import GridComponent from "../SuperClasses/GridComponent";
import { CircularProgress, Paper, TextField, InputLabel, MenuItem, FormControl, Select, Stack } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
//import _ from "lodash";
import { GET_NEWS_MEDIAMONITOR, DEFAULT_FETCH_HEADERS, GET_NEWS_FILTRED_MEDIAMONITOR } from "../config";


export default class MediaSearchWidget extends GridComponent {
  constructor(props) {
    super(props);
    const currentDate = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(currentDate.getDate() - 7);
  
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are 0 based index
      const day = ("0" + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    };
  
    this.state = { 
      ...props,  
      keyword: 'AI',
      from: formatDate(oneWeekAgo), 
      to: formatDate(currentDate), 
      language: 'en',
      context: 'Relevant for Public Affairs work.', 
      message: ''
    };
    this.initComponent({ type: "NewsFetcher", title: "media search" });
  }

  handleError = (error) => {
    console.error('Error:', error);
    toast.error('An error occurred while fetching data.');
  };
  
  fetchDataFromEndpoint = async (endpoint, requestOptions) => {
    try {
      const response = await fetch(endpoint, requestOptions);
      const data = await response.json();
      return data;
    } catch (error) {
      this.handleError(error);
    }
  };
  
  fetchData = () => {
    console.log('Button pressed: Fetching data');
    console.log('Current state:', this.state);

    this.setState({ loading_intro: true, loading_summary: true, loading_links: true });
  
    if (!this.state.keyword) {
      toast.error("Keyword field must be filled");
      return;
    }
  
    const newsRequestOptions = {
      method: 'POST',
      headers: { ...DEFAULT_FETCH_HEADERS().headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({
        q: this.state.keyword,
        from_param: this.state.from,
        to: this.state.to,
        language: this.state.language
      })
    };
  
    const endpoint = GET_NEWS_MEDIAMONITOR;
  
    console.log('Request options:', newsRequestOptions);
    console.log('Endpoint:', endpoint);
  
    this.fetchDataFromEndpoint(endpoint, newsRequestOptions)
      .then(data => {
        if (data.status === 'success') {
          const filename = data.file_name;
          const secondEndpoint = GET_NEWS_FILTRED_MEDIAMONITOR;
          const secondRequestOptions = {
            method: 'POST',
            headers: { ...DEFAULT_FETCH_HEADERS().headers, 'Content-Type': 'application/json' },
            body: JSON.stringify({
              blob_name: filename,
              context: this.state.context
            })
          };
          
          console.log('Second request options:', secondRequestOptions); 

          this.fetchDataFromEndpoint(secondEndpoint, secondRequestOptions)
            .then(data => {
              console.log('Response data:', data); // Add this line
            
              if (data.status === 'success') {
                const articles = data.data;
                this.setState({ articles, message: '' });
              } else if (data.status === 'no_content') {
                toast.info('No relevant articles were found.');
                this.setState({ message: 'No relevant articles were found.' }, () => {
                  console.log(this.state);
                });
              } else {
                console.error('Error: Failed to fetch articles');
                toast.error('Failed to fetch articles. Please try again later.');
              }
              this.setState({ loading_intro: false, loading_summary: false, loading_links: false });
            });
        } else {
          console.error('Error: Failed to fetch data from first endpoint');
          toast.error('Failed to fetch data from first endpoint. Please check your connection and try again.');
        }
      });
  };

handleButtonClick = () => {
  console.log('Button clicked');
  this.fetchData();
};


  handleChange = (event) => {
    const loadingState = event.target.value && this.state.date
    this.setState({ ...this.state, topic: event.target.value, loading_intro: loadingState, loading_summary: loadingState, loading_links: loadingState, displayError: false });
  };


  render_main_content() {
    
    if (this.state.loading_intro || this.state.loading_summary || this.state.loading_links) {
      return (
        <div className="flex-center">
          <CircularProgress />
        </div>
      );
    } else {

      return (
        <div>
          {this.state.message && <p>{this.state.message}</p>} 
          {this.state.articles?.map((article, index) => {
            return (
              <div key={index}>
                <h2>{article.title}</h2>
                <p>{article.description}</p>
                <a href={article.url} target="_blank" rel="noopener noreferrer">Read more</a>
              </div>
            )
          })}
        </div>
      );
    }
  }

render() {
  return (
    <Paper className="card">
      {this.renderTopBar()}
      <div className="card__body">
        <div className="card__body-inner">
          <Stack className={"search"} marginTop={0.5} direction={"column"} spacing={3}>
            <FormControl fullWidth className="keyword_input_field" size="small">
            <TextField
              fullWidth
              type="search"
              variant="outlined"
              label="Keywords..."
              onKeyDown={this.onKeyDown}
              onChange={(event) => this.setState({ keyword: event.target.value })}
            />
            </FormControl>

            <FormControl fullWidth className="context_input_field" size="small">
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                label="Context..."
                onChange={(event) => this.setState({ context: event.target.value })}
              />
            </FormControl>

            <Stack direction="row" spacing={1}>
              <FormControl className="from-input-field" size="small" sx={{ width: '50%' }}>
                <TextField
                  label="From"
                  type="date"
                  value={this.state.from}
                  onChange={(event) => this.setState({ from: event.target.value })}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>

              <FormControl className="to-input-field" size="small" sx={{ width: '50%' }}>
                <TextField
                  label="To"
                  type="date"
                  value={this.state.to}
                  onChange={(event) => this.setState({ to: event.target.value })}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Stack>

            <FormControl fullWidth size="small" className="language-input-field">
              <InputLabel>Language</InputLabel>
              <Select
                fullWidth
                variant="outlined"
                value={this.state.language}
                onChange={(event) => this.setState({ language: event.target.value })}
              >
                <MenuItem value={"en"}>English</MenuItem>
                <MenuItem value={"de"}>German</MenuItem>
                <MenuItem value={"fr"}>French</MenuItem>
                {/* Add more languages as needed */}
              </Select>
            </FormControl>

            <div className={"button is--primary"} variant={"contained"} color={"primary"} onClick={this.handleButtonClick}>Search</div>
          </Stack>
        </div>
        <div className="card__body-inner">{this.render_main_content()}</div>
      </div>
    </Paper>
  );
}
}
