import React from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
export default class GridComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...props };
        this.properties = {}
    }

    initComponent = (properties) => {
        this.properties = properties
    }

    renderTopBar = () => {
        return (
            <Stack sx={{ padding: "5px 15px 5px 15px" }} direction="row"
                className={"drag-handle card__header"}>
                <div className={"card__title"}>{this.properties.title}</div>
                <div className={"card_controls"}>
                    <IconButton className={"control-button"} size={"small"} >
                        <HelpOutlineIcon />
                    </IconButton>
                    <IconButton className={"control-button"} size={"small"} onClick={this.onCloseClicked}>
                        <CloseIcon className={"control-button__inner w-embed"} />
                    </IconButton>
                </div>
            </Stack>
        )
    }


    onCloseClicked = (event) => {
        this.state.parentGrid.onRemoveItem(this.state.gridRepresentation.i)
    }
}
