import React, { Component } from "react";
import Chart from "react-apexcharts";
import PDataGrid from "../InCardWidgets/PDataGrid";
import { Box, CircularProgress, Divider, IconButton, Paper, Stack } from "@mui/material";

export default class TimeSeriesChart extends Component {
    constructor(props) {
        super(props);
        let initialDocumentsState = this.props.documents.flat() || [];
        if (this.props.series === undefined) {
            this.state = {
                documentsState: initialDocumentsState,  // Nueva variable de estado para documentos
                parent: props.parent,
                options: {
                    chart: {
                        id: "basic-timeseries",
                        type: "area",
                        stacked: "false",
                        offsetX: 0,
                        offsetY: 0,
                        zoom: {
                            autoScaleYaxis: true
                        },
                        events: {
                            // click: function (event, chartContext, { seriesIndex, dataPointIndex }) => {
                            click: (event, chartContext, { seriesIndex, dataPointIndex }) => {
                                console.log("TimeSeriesChart clicked");
                                if (seriesIndex !== -1 && dataPointIndex !== -1) {
                                    const series = chartContext.w.config.series;
                                    const seriesData = series[seriesIndex];
                                    const dataPoint = seriesData.data[dataPointIndex];
                                    const docuPoint = seriesData.documentsState[dataPointIndex];
                                    this.updateDocuments(docuPoint);
                                    // console.log("Clicked series:", seriesData.name);
                                    // console.log("Clicked data point value:", dataPoint);
                                    // console.log("docuPoint:", docuPoint);
                                } else {
                                    console.log("Clicked outside a data point");
                                    this.updateDocuments(this.props.documents.flat() || []);
                                }
                            }
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    axisTicks: {
                        show: true
                    },
                    markers: {
                        size: 5,
                        style: 'hollow',
                    },
                    xaxis: {
                        type: 'datetime',
                        tickAmount: 1,
                        categories: this.props.categories,
                        labels: {
                            rotate: -30,
                            format: 'dd-MM-yy'
                        },
                    }
                },
                series: [
                    {
                        name: this.props.seriesName,
                        data: this.props.values,
                        documentsState: this.props.documents
                    }
                ]
            };
        }
        else {
            this.state = {
                parent: props.parent,
                options: {
                    chart: {
                        type: 'area',
                        stacked: false,
                        height: 350,
                        zoom: {
                            enabled: true
                        },
                        events: {
                            drawDotChart: (event, chartContext, { seriesIndex, dataPointIndex, config }) => {
                                console.log({ seriesIndex, dataPointIndex, config })
                                if (props.onMarkerClickHandler !== undefined) {
                                    props.onMarkerClickHandler(event, chartContext, { seriesIndex, dataPointIndex, config }, this.state.series[seriesIndex]["data"][dataPointIndex][0])
                                }
                            }
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    markers: {
                        size: 4,
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shadeIntensity: 1,
                            inverseColors: false,
                            opacityFrom: 0.45,
                            opacityTo: 0.05,
                            stops: [20, 100, 100, 100]
                        },
                    },
                    yaxis: {
                        labels: {
                            style: {
                                colors: '#8e8da4',
                            },
                            offsetX: 0,
                        },
                        axisBorder: {
                            show: false,
                        },
                        axisTicks: {
                            show: false
                        }
                    },
                    xaxis: {
                        type: 'datetime',
                        tickAmount: 8,
                        labels: {
                            rotate: -15,
                            rotateAlways: true,
                        }
                    },
                    tooltip: {
                        intersect: true,
                        shared: false
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'center',
                        offsetX: 10
                    },

                },
                series: this.props.series
            };
        }
    }


    updateDocuments = (docuPoint) => {
        this.setState({ documentsState: docuPoint ? [...docuPoint] : [] });
    }

    renderDataGrid = () => {
        let rows = []
        let columns = []
        this.state.documentsState.forEach((e, idx) => {
            // console.log(e)
            // console.log(idx)
            // e[Object.keys(e)[0]]["documents"].forEach(x => {

            if (this.state.filterData !== undefined) {
                let filterDate = new Date(Date.parse(this.state.filterData.dataPoint));
                let currentDate = new Date(e["Date"]);
                if (idx !== this.state.filterData.series) {
                    return;
                }
                if (filterDate.getFullYear() !== currentDate.getFullYear() || filterDate.getMonth() !== currentDate.getMonth()) {
                    return
                }
            }
            rows.push(e)
            // console.log('rows', rows)
            // })
        });
        ["id", "Type", "Title", "Date"].forEach(e => {
            columns.push({
                field: e,
                headerName: e,
                accessor: 'accessor',
                editable: false,
                hide: e.toLowerCase().includes("id"),
                flex: 1
            })
        })
        return <PDataGrid columns={columns} rows={rows} parent={this} />
    }

    render() {

        return (

            <Stack height={"100%"}>
                <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="area"
                    width="95%"
                    height="50%"
                />
                <Divider />

                {/* Renderizar renderDataGrid condicionalmente */}
                {this.state.documentsState && this.state.documentsState.length > 0 && this.renderDataGrid()}

            </Stack>

        )

        // return (
        //     <div className="timeSeriesChart">
        //         <div className="row">
        //             <div className="mixed-chart">
        //                 <Chart
        //                     options={this.state.options}
        //                     series={this.state.series}
        //                     type="area"
        //                     width="95%"
        //                     height="270vh"
        //                 />
        //                 <Divider />

        //                 {/* Renderizar renderDataGrid condicionalmente */}
        //                 {this.state.documents.length > 0 && this.renderDataGrid()}
        //             </div>
        //         </div>
        //     </div>
        // );
    }
}