import GridComponent from "../SuperClasses/GridComponent";
import {Alert, Box, Button, CircularProgress, Divider, Paper, Snackbar, Stack, TextField} from "@mui/material";
import {Pagination} from "@mui/lab";
import {BASE_URL, DEFAULT_FETCH_HEADERS} from "../config";
import React from "react";
import {toast} from "react-toastify";

export default class PromptWidget extends GridComponent
{
    constructor(props) {
        super(props);
        this.initComponent({"type": "PromptWidget", "title": "OpenAI Prompt"})
        this.state = {...props, loading:false, inputText: "", maxTokens: 120, responseCode: 200, displayError: false}
    }

    onInputChanged = (event) =>
    {
        this.setState((prev) => ({...prev, inputText: event.target.value}))
    }

    onTokensChanged = (event) =>
    {
        this.setState((prev) => ({...prev, maxTokens: Number(event.target.value)}))
    }

    handleResponseCode = (status) =>
    {
        if(status=== 429)
        {
            toast.error("Exhausted rate limit. Please wait 60 seconds before trying again.")
        }
        else if(status === 200 && this.state.error !== undefined)
        {
            toast.error("Something went wrong! (Blame it on the Beta)")
        }
    }

    onGoClicked = (event) =>
    {
        const requestOptions = {
            method: 'POST',
            headers: {...DEFAULT_FETCH_HEADERS().headers, 'Content-Type': 'application/json'},
            body: JSON.stringify({prompt: this.state.inputText, max_tokens: this.state.maxTokens})
        };
        fetch(BASE_URL + "/tasks/completions", {...requestOptions})
            .then((response) => {this.handleResponseCode(response.status); return response.json()})
            .then((data) => this.setState((prev) => ({...prev,loading:false, responseCode: this.responseCode, displayError: this.responseCode !== 200, oaiResponse: data["result"]})))
            .catch((error) => this.setState((prev) => ({...prev, error: error, loading:false,  displayError: true})));
        this.setState((prev) => ({...prev,loading:true}))
    }

    renderErrorBar = () =>
    {
        if(this.state.responseCode === 429)
        {
            return(
                <Snackbar open={this.state.displayError} autoHideDuration={5000} onClose={()=>this.setState((prev) => ({...prev, displayError: false}))} anchorOrigin={{ vertical:"bottom", horizontal:"center" }}>
                    <Alert severity={"error"}>Exhausted rate limit. Please wait 60 seconds before trying again.</Alert>
                </Snackbar>
            )
        }
        else if(this.state.responseCode === 200 && this.state.error !== undefined)
        {
            return(
                <Snackbar open={this.state.displayError} autoHideDuration={5000} onClose={()=>this.setState((prev) => ({...prev, displayError: false}))} anchorOrigin={{ vertical:"bottom", horizontal:"center" }}>
                    <Alert severity={"error"}>Something went wrong! (Blame it on the Beta)</Alert>
                </Snackbar>
            )
        }
    }

    render()
    {
        return(
            <React.Fragment>
                <Paper className="card" elevation={0}>
                    {this.renderTopBar(false)}
                    <div className="card__body">
                        <div className="card__body-inner">
                            <Stack maxHeight={"100%"} direction={"column"} spacing={1.5}>
                                <TextField
                                    label="Input"
                                    placeholder="Lorem Ipsum"
                                    multiline
                                    fullWidth
                                    onChange={(event) => this.onInputChanged(event)}
                                    rows={9}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <Stack direction={"row"} spacing={1}>
                                    <TextField
                                        label="Output length"
                                        defaultValue={120}
                                        type="number"
                                        onChange={(event) => this.onTokensChanged(event)}
                                        size={"small"}
                                        className={"text"}
                                    />
                                    <Button variant={"contained"} fullWidth className={"button is--primary"} onClick={this.onGoClicked}>GO</Button>
                                </Stack>
                                <Alert severity={this.state.inputText.length + this.state.maxTokens > 4097? "error":"success"}>
                                    {this.state.inputText.length} + {this.state.maxTokens} = {this.state.inputText.length + this.state.maxTokens} / 4097 Tokens ({(((this.state.inputText.length + this.state.maxTokens)/1000)*0.02).toFixed(4)}$)
                                </Alert>
                                <Divider/>
                                {this.state.loading ?
                                    <Box display={"flex"} height={"25vh"} alignItems={"center"} justifyContent={"center"}><CircularProgress/></Box>:
                                    <TextField
                                        label="Output"
                                        placeholder="Waiting for request..."
                                        value={this.state.oaiResponse === undefined? "Waiting for input...":this.state.oaiResponse.replace(/^\s+|\s+$/g, '')}
                                        multiline
                                        fullWidth
                                        rows={8}
                                        InputProps={{readOnly: true}}
                                        InputLabelProps={{ shrink: true, }}
                                    />
                                }
                            </Stack>
                        </div>
                    </div>
                </Paper>
                {this.renderErrorBar()}
            </React.Fragment>
        )

    }
}

