import React, {Component, useRef} from "react";
import {Box, Paper} from "@mui/material";
import {pdfjs} from "react-pdf/dist/umd/entry.webpack5";
import {Page} from "react-pdf";
import {Document} from "react-pdf/dist/esm/entry.webpack5";
import {Pagination} from "@mui/lab";
import GridComponent from "../SuperClasses/GridComponent";
import {BASE_URL} from "../config";
pdfjs.GlobalWorkerOptions.workerSrc =  BASE_URL.replace("/api","")+"/scripts/pdf.worker.min.js";


export class PDFWidget extends GridComponent
{
    constructor(props) {
        super(props);
        this.pdfWrapper = React.createRef()
        this.initComponent({"type": "PDFWidget", "title": "PDF Document"})
    }

    onPDFLoadSuccess = ({ numPages }) =>
    {
        this.setState({"totalPages":numPages, "currentPage":1})
    }

    onPageChange = (event, value) =>
    {
        this.setState({"currentPage":value})
    }

    getDivWidth = () =>
    {
        return this.pdfWrapper.current != null ? this.pdfWrapper.current.getBoundingClientRect().width : "600"
    }

    renderPDF = () =>
    {
        const file = this.props.pdf ? `data:application/pdf;base64,${this.props.pdf}` : this.props.pdfUrl
        return([
            <Box flex={1} overflow={"auto"}>
                <div id={"pdfWrapper"} style={{width: "100%"}} ref={this.pdfWrapper}>
                    <Document onLoadSuccess = {this.onPDFLoadSuccess} file={file}>
                        <Page  pageNumber={this.state.currentPage ? this.state.currentPage : 1} width={this.getDivWidth()}/>
                    </Document>
                </div>
            </Box>])
    }



    render()
    {

        return(
            <Paper className="card">
                {this.renderTopBar()}
                <div className="card__body">
                    <div className="card__body-inner">
                        {this.renderPDF()}
                    </div>
                </div>
                <Pagination count={this.state.totalPages} sx={{alignSelf:"center",}} onChange={this.onPageChange} />
            </Paper>
        )

    }
}