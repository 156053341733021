import GridComponent from "../SuperClasses/GridComponent";
import { CircularProgress, Paper, TextField, InputLabel, MenuItem, FormControl, Select, Stack } from "@mui/material";
import React from "react";
import '../../App.css';
import { toast } from "react-toastify";
//import _ from "lodash";
import { DEFAULT_FETCH_HEADERS, GET_SCHEMA_SEARCH, GET_SCHEMA_SEARCH_CSV } from "../config";


const LIST = [
  { label: "Test", value: "test_schema" },
  { label: "Avanci", value: "avanci_schema" },
  { label: "Enter", value: "enter_schema" },
  { label: "Gerolsteiner", value: "gerolsteiner_schema" }
];

export default class MediaMonitorWidget extends GridComponent {
  constructor(props) {
    super(props);
    const currentDate = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(currentDate.getDate() - 7);
  
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are 0 based index
      const day = ("0" + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    };

    this.downloadCsv = this.downloadCsv.bind(this);
  
    this.state = { 
      ...props,  
      schema_name: 'default_schema', // replace with your default schema name
      from: formatDate(oneWeekAgo), 
      to: formatDate(currentDate), 
      language: 'de',
      message: '',
      articles: {}
    };
    this.initComponent({ type: "NewsFetcher", title: "media monitor" });
  }

  handleError = (error) => {
    console.error('Error:', error);
    toast.error('An error occurred while fetching data.');
  };
  
  fetchDataFromEndpoint = async (endpoint, requestOptions) => {
    try {
      const response = await fetch(endpoint, requestOptions);
      const data = await response.json();
      return data;
    } catch (error) {
      this.handleError(error);
    }
  };
  
  fetchData = () => {
    console.log('Button pressed: Fetching data');
    console.log('Current state:', this.state);
  
    this.setState({ loading_intro: true, loading_summary: true, loading_links: true });
  
    if (!this.state.schema_name) {
      toast.error("Schema name field must be filled");
      return;
    }
  
    const requestOptions = {
      method: 'POST',
      headers: { ...DEFAULT_FETCH_HEADERS().headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({
        schema_name: this.state.schema_name,
        from_param: this.state.from,
        to: this.state.to,
        language: this.state.language
      })
    };

    const endpoint = GET_SCHEMA_SEARCH;
  
    this.fetchDataFromEndpoint(endpoint, requestOptions)
    .then(data => {
      console.log('Fetch response:', data); 
    
      if (data.status === 'success') {
        const articles = data.articles;
        this.setState({ 
          articles, 
          blob_name: data.blob_name, // store blob_name in the state
          message: '', 
          loading_intro: false, 
          loading_summary: false, 
          loading_links: false 
        });
      } else {
        console.error('Error: Failed to fetch data from endpoint');
        toast.error('Failed to fetch data from endpoint. Please check your connection and try again.');
      }
});
};

downloadCsv = () => {
  const requestOptions = {
    method: 'POST',
    headers: { ...DEFAULT_FETCH_HEADERS().headers, 'Content-Type': 'application/json' },
    body: JSON.stringify({
      json_name: this.state.blob_name // use blob_name as json_name
    })
  };

  fetch(GET_SCHEMA_SEARCH_CSV, requestOptions)
    .then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'articles.csv';
        a.click();
      });
    })
    .catch(error => console.error('Error:', error));
};


handleButtonClick = () => {
  console.log('Button clicked');
  this.fetchData();
};


  handleChange = (event) => {
    const loadingState = event.target.value && this.state.date
    this.setState({ ...this.state, topic: event.target.value, loading_intro: loadingState, loading_summary: loadingState, loading_links: loadingState, displayError: false });
  };


  render_main_content() {
    console.log('Articles:', this.state.articles); // Log the articles object
  
    if (this.state.loading_intro || this.state.loading_summary || this.state.loading_links) {
      return (
        <div className="flex-center">
          <CircularProgress />
        </div>
      );
    } else {
      return (
        <div>
          {this.state.message && <p>{this.state.message}</p>} 
          {Array.isArray(this.state.articles) && this.state.articles.map((article, index) => {
            return (
              <div key={index}>
                <h2>{article.title}</h2>
                <p>{article.description}</p>
                <a href={article.url} target="_blank" rel="noopener noreferrer">Read more</a>
              </div>
            )
          })}
        </div>
      );
    }
  }

  render() {
    return (
      <Paper className="card">
        {this.renderTopBar()}
        <div className="card__body">
          <div className="card__body-inner">
            <Stack className={"search"} marginTop={0.5} direction={"column"} spacing={3}>
            <FormControl fullWidth className="schema_name_input_field" size="small">
              <InputLabel>Schema Name</InputLabel>
              <Select
                fullWidth
                variant="outlined"
                value={this.state.schema_name}
                onChange={(event) => this.setState({ schema_name: event.target.value })}
              >
                {LIST.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

              <Stack direction="row" spacing={1}>
              <FormControl className="from-input-field" size="small" sx={{ width: '50%' }}>
                <TextField
                  label="From"
                  type="date"
                  value={this.state.from}
                  onChange={(event) => this.setState({ from: event.target.value })}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>

              <FormControl className="to-input-field" size="small" sx={{ width: '50%' }}>
                <TextField
                  label="To"
                  type="date"
                  value={this.state.to}
                  onChange={(event) => this.setState({ to: event.target.value })}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Stack>

            <FormControl fullWidth size="small" className="language-input-field">
              <InputLabel>Language</InputLabel>
              <Select
                fullWidth
                variant="outlined"
                value={this.state.language}
                onChange={(event) => this.setState({ language: event.target.value })}
              >
                <MenuItem value={"de"}>German</MenuItem>
                <MenuItem value={"en"}>English</MenuItem>
                <MenuItem value={"fr"}>French</MenuItem>
                {/* Add more languages as needed */}
              </Select>
            </FormControl>

            <div className={"button is--primary"} variant={"contained"} color={"primary"} onClick={this.handleButtonClick}>Search</div>
            <div className="button-container">
              <div 
                className={`button is--secondary download-button ${!this.state.blob_name ? 'disabled' : ''}`} onClick={this.state.blob_name ? this.downloadCsv : null}> Download CSV
              </div>
            </div>
            </Stack>
          </div>
          <div className="card__body-inner">
        {this.render_main_content()}
      </div>
        </div>
      </Paper>
    );
  }
}