import {Divider, Menu, MenuItem, Modal} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import CountUp from "react-countup";
import {Component} from "react";
import {logOut} from "../../utils/firebase";


export default class Footer extends Component
{
    constructor(props) {
        super(props);
        this.user = props.user
        this.userMenuAnchor = null;
        this.state = {isUserMenuOpen: Boolean(this.userMenuAnchor), isStakeholderModalOpen: false}
    }

    onSHModalClose = () =>
    {
        this.setState((prev) => ({...prev, isStakeholderModalOpen:false}))
    }

    render() {
        return(
            [
                <div className="navbar footer">
                    <div className="navbar__inner">
                        <div className="navbar__menu">
                            <div className="navbar__links">
                                <div className="nav">
                                    <div className="nav__link">Legal Notice</div>
                                    <div className="nav__link">Data Ethics</div>
                                    <div className="nav__link">Barrier-Free Access</div>
                                    <div className="nav__link">Explain</div>                     
                                </div>
                            </div>
                            <div className="nav__link logout__link" onClick={(event) => (logOut())}><LogoutIcon fontSize="18px" className="logout__icon"></LogoutIcon>Log Out</div>
                        </div>
                    </div>
                </div>])

    }
}