import { Link } from "@mui/material";
import { Component } from "react";
import StakeholderModal from "../InCardWidgets/StakeholderModal.tsx";
import Search from "../StandaloneWidgets/Search";
import PromptWidget from "../StandaloneWidgets/PromptWidget";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NewsletterWidget from "../StandaloneWidgets/NewsletterWidget";
import MediaSummarizerWidget from "../StandaloneWidgets/MediaSummarizerWidget";
import UploadWidget from "../StandaloneWidgets/UploadWidget";
import AnalyserWidget from "../StandaloneWidgets/AnalyserWidget";
import MediaSearchWidget from "../StandaloneWidgets/MediaSearchWidget.js";
import MediaMonitorWidget from "../StandaloneWidgets/MediaMonitorWidget.js";



export default class InfoBar extends Component {
    constructor(props) {
        super(props);
        this.user = props.user
        this.userMenuAnchor = null;
        this.state = { isUserMenuOpen: Boolean(this.userMenuAnchor), isStakeholderModalOpen: false }
    }

    onSHModalClose = () => {
        this.setState((prev) => ({ ...prev, isStakeholderModalOpen: false }))
    }

    onOpenOAI = () => {
        if (this.props.gridRef.current) {
            this.props.gridRef.current.createNewComponent({ w: 3, h: 6, component: PromptWidget })
        }
    }

    onOpenSearch = () => {
        if (this.props.gridRef.current) {
            this.props.gridRef.current.createNewComponent({ w: 5, h: 2, component: Search })
        }
    }

    onOpenSummariser = () => {
        if (this.props.gridRef.current) {
            this.props.gridRef.current.createNewComponent({ w: 5, h: 2, component: MediaSummarizerWidget })
        }
    }

    onOpenNewsletter = () => {
        if (this.props.gridRef.current) {
            this.props.gridRef.current.createNewComponent({ w: 5, h: 6, component: NewsletterWidget })
        }
    }

    onUploadDocument = () => {
        if (this.props.gridRef.current) {
            this.props.gridRef.current.createNewComponent({ w: 3, h: 3, component: UploadWidget })
        }
    }
        
    onOpenAnalyser = () =>
    {
        if (this.props.gridRef.current)
        {
            this.props.gridRef.current.createNewComponent({w:7,h:8, component:AnalyserWidget})
        }
    }

    onOpenMediaSearch = () => {
        if (this.props.gridRef.current) {
            this.props.gridRef.current.createNewComponent({ w: 7, h: 4, component: MediaSearchWidget })
        }
    }

    onOpenMediaMonitor = () => {
        if (this.props.gridRef.current) {
            this.props.gridRef.current.createNewComponent({ w: 7, h: 4, component: MediaMonitorWidget })
        }
    }

    render() {
        return (
            [
                <StakeholderModal open={this.state.isStakeholderModalOpen} gridRef={this.props.gridRef} closeCallback={this.onSHModalClose} />,
                <div className="navbar top_bar">
                    <div className="navbar__inner">
                        <img src="/inq_logo_blue.png" loading="lazy" alt="" className="navbar__logo" />
                        <div className="navbar__menu">
                            <div className="navbar__links">
                                <div className="nav">
                                    <div className="nav__link is--dropdown">
                                        <div className="nav__link-text">tools.<ExpandMoreIcon fontSize="16px" className="nav__link-icon" /></div>
                                        <div className="nav__dropdown">
                                            <div className="nav--has-divider">inQ-Tools</div>

                                            <Link className="dropdown-link" href={"https://46.101.112.169:8501/"} underline="none" target="_blank" rel="noopener">
                                                <div className="nav__dropdown-item">networker. <ExitToAppIcon fontSize="18px" sx={{ margin: "0.3em" }} />
                                                </div>
                                            </Link>

                                            <Link className="dropdown-link" underline="none" rel="noopener" onClick={(event) => { this.onOpenSummariser() }}>
                                                <div className="nav__dropdown-item">
                                                    summariser. 
                                                </div>
                                            </Link>
                                            <div className="nav__dropdown-item" onClick={(event) => {this.onOpenAnalyser()}}>analyser.</div>
                                            
                                            <div className="nav__dropdown-item" onClick={(event) => { this.onOpenMediaSearch() }}>media search.</div>

                                            <div className="nav__dropdown-item" onClick={(event) => { this.onOpenMediaMonitor() }}>media monitor.</div>

                                            <div className="dropdown-link dead-link" underline="none">
                                                <div className="nav__dropdown-item">inQGPT <ExitToAppIcon fontSize="18px" sx={{ margin: "0.3em" }} />
                                                    </div>
                                                </div>

                                            <div className="nav__dropdown-item" onClick={(event) => { this.onOpenNewsletter() }}>newsfeed.</div>
                                            <div className="nav--has-divider">AI-Tools</div>

                                            <div className="nav__dropdown-item nav--is-after-divider" onClick={(event) => { this.onOpenOAI() }}>OpenAI</div>

                                            <Link className="dropdown-link" href={"https://app.aleph-alpha.com/"} underline="none" target="_blank" rel="noopener">
                                                <div className="nav__dropdown-item">Aleph Alpha <ExitToAppIcon fontSize="18px" sx={{ margin: "0.3em" }} />
                                                </div>
                                            </Link>
                                            <Link className="dropdown-link" href={"https://bard.google.com/u/3/?hl=en"} underline="none" target="_blank" rel="noopener">
                                                <div className="nav__dropdown-item">Google Bard <ExitToAppIcon fontSize="18px" sx={{ margin: "0.3em" }} />
                                                </div>
                                            </Link>
                                            <Link className="dropdown-link" href={"https://MistralAI"} underline="none" target="_blank" rel="noopener">
                                                <div className="nav__dropdown-item">MistralAI <ExitToAppIcon fontSize="18px" sx={{ margin: "0.3em" }} />
                                                </div>
                                            </Link>
                                            <Link className="dropdown-link" href={"https://www.deepl.com/translator"} underline="none" target="_blank" rel="noopener">
                                                <div className="nav__dropdown-item">DeepL <ExitToAppIcon fontSize="18px" sx={{ margin: "0.3em" }} />
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="nav__link is--dropdown">
                                        <div className="nav__link-text">pool.<ExpandMoreIcon fontSize="16px" className="nav__link-icon" /></div>
                                        <div className="nav__dropdown">
                                            <div className="nav__dropdown-item is--dead-link">academy.</div>
                                            <div className="nav__dropdown-item is--dead-link">experts.</div>
                                            <div className="nav__dropdown-item" onClick={(event) => { this.setState((prev) => ({ ...prev, isStakeholderModalOpen: true })) }}>search stakeholders.</div>

                                        </div>
                                    </div>
                                    <div className="nav__link is--dropdown">
                                        <div className="nav__link-text">data. <ExpandMoreIcon fontSize="16px" className="nav__link-icon" /></div>

                                        <div className="nav__dropdown">
                                            {/* <div className="nav__dropdown-item" onClick={(event) => { this.onOpenSearch() }}>new search.</div> */}

                                            <Link className="dropdown-link" underline="none" rel="noopener" onClick={(event) => { this.onOpenSearch() }}>
                                                <div className="nav__dropdown-item">
                                                    new search. <ExitToAppIcon fontSize="18px" sx={{ margin: "0.3em" }} />
                                                </div>
                                            </Link>
                                            {/* <div className="nav__dropdown-item is--dead-link">+ Create project</div>
                                            <div className="nav__dropdown-item is--dead-link">+ Add Monitoring</div> */}
                                            <Link className="dropdown-link" href={"https://f23n1r79rrw.typeform.com/to/yiSNXxhO"} underline="none" target="_blank" rel="noopener">
                                                <div className="nav__dropdown-item">
                                                    + stakeholders. <ExitToAppIcon fontSize="18px" sx={{ margin: "0.3em" }} />
                                                </div>
                                            </Link>
                                            <Link className="dropdown-link" href={"https://f23n1r79rrw.typeform.com/to/tdi5vnU0"} underline="none" target="_blank" rel="noopener">
                                                <div className="nav__dropdown-item">
                                                    + media <ExitToAppIcon fontSize="18px" sx={{ margin: "0.3em" }} />
                                                </div>
                                            </Link>
                                                <div className="nav__dropdown-item"  onClick={this.onUploadDocument}>+ book/document</div>
                                            {/* <div className="nav__dropdown-item is--dead-link">+ Add Expert</div>
                                            <Link className="dropdown-link" href={"https://f23n1r79rrw.typeform.com/to/iQamLuGu"} underline="none" target="_blank" rel="noopener">
                                                <div className="nav__dropdown-item">
                                                    + Add Academy <ExitToAppIcon fontSize="18px" sx={{ margin: "0.3em" }} />
                                                </div>
                                            </Link>
                                            <Link className="dropdown-link" href={"https://f23n1r79rrw.typeform.com/to/gfDj6Nn0"} underline="none" target="_blank" rel="noopener">
                                                <div className="nav__dropdown-item">
                                                    Join Pool <ExitToAppIcon fontSize="18px" sx={{ margin: "0.3em" }} />
                                                </div>
                                            </Link> */}
                                            <div className="nav--has-divider">DATABASES</div>
                                            <Link className="dropdown-link" href={"https://www.kuerschners.com/de/datenbanken/kuerschners-crm"} underline="none" target="_blank" rel="noopener">
                                                <div className="nav__dropdown-item">Kürschner<ExitToAppIcon fontSize="18px" sx={{ margin: "0.3em" }} />
                                                </div>
                                            </Link>
                                            <Link className="dropdown-link" href={"https://www.pressemonitor.de/"} underline="none" target="_blank" rel="noopener">
                                                <div className="nav__dropdown-item">PMG-Monitor<ExitToAppIcon fontSize="18px" sx={{ margin: "0.3em" }} />
                                                </div>
                                            </Link>
                                            <Link className="dropdown-link" href={"https://www.statista.com/"} underline="none" target="_blank" rel="noopener">
                                                <div className="nav__dropdown-item">Statista <ExitToAppIcon fontSize="18px" sx={{ margin: "0.3em" }} />
                                                </div>
                                            </Link>
                                            <Link className="dropdown-link" href={"https://civey.com/login?redirect=%2Fressourcen%2Funsere-arbeit"} underline="none" target="_blank" rel="noopener">
                                                <div className="nav__dropdown-item">Civey <ExitToAppIcon fontSize="18px" sx={{ margin: "0.3em" }} />
                                                </div>
                                            </Link>

                                            <Link className="dropdown-link" href={"https://notion.so"} underline="none" target="_blank" rel="noopener">
                                                <div className="nav__dropdown-item">Notion <ExitToAppIcon fontSize="18px" sx={{ margin: "0.3em" }} />
                                                </div>
                                            </Link>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div >])

    }
}
