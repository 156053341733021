import './App.css';
import { Grid } from "./components/StaticWidgets/Grid"
import { Alert, Box, CircularProgress, createTheme, Snackbar, Stack, ThemeProvider } from "@mui/material";
import InfoBar from "./components/StaticWidgets/AppBar";
import Footer from "./components/StaticWidgets/Footer";
import React, { Component } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./utils/firebase";
import { VERIFY_TOKEN } from "./components/config";
import LoginWidget from "./components/StaticWidgets/Login";



const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#3f4e55',
        },
        secondary: {
            main: '#3f4e55',
        },
        googleRed: {
            main: '#EA4335',
            contrastText: '#fff',
        },
        msBlue: {
            main: '#00a2ed',
            contrastText: '#fff',
        }
    },
    typography: {
        fontFamily: [
            'Source Sans Pro',
            '"Helvetica Neue"',
            'sans-serif',
        ].join(','),
    },
    components: {
        MuiIconButton: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        backgroundColor: "#d7eaf9",
                        color: "#1d73b4"
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "30px",
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.12)",
                }
            }
        }
    }

});


class App extends Component {
    constructor(props) {
        super(props);
        this.observerPlaced = false;
        this.gridRef = React.createRef();
        this.state = {
            isLoggedIn: false,
            user: undefined,
            showWelcomeBackDialog: true
        };

    }

    async componentDidMount() {
        if (!this.observerPlaced) {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    user.getIdToken(/* forceRefresh */ true).then((idToken) => {
                        const requestOptions = {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': "Bearer " + idToken
                            },
                        };
                        // fetch(BASE_URL + "/users/me", requestOptions)
                        fetch(VERIFY_TOKEN, requestOptions)
                            .then((response) => {
                                sessionStorage.setItem('auth', idToken);
                                this.setState(prev => ({ ...prev, user: user, isLoggedIn: response.status === 200 }))
                            })
                    })
                        .catch((error) => {
                            this.setState(prev => ({ ...prev, user: null, isLoggedIn: false }))
                        })
                } else {
                    this.setState(prev => ({ ...prev, user: null, isLoggedIn: false }))
                }
            });
            this.observerPlaced = true
        }

    }

    render() {

        if (this.state.isLoggedIn) {
            return (
                <div className="App">
                    <ThemeProvider theme={theme}>
                        <Snackbar open={this.state.showWelcomeBackDialog} autoHideDuration={5000} onClose={(event) => (this.setState(prev => ({ ...prev, showWelcomeBackDialog: false })))}>
                            <Alert severity="success">
                                Welcome Back, {this.state.user.displayName != null ? this.state.user.displayName : this.state.user.email}
                            </Alert>
                        </Snackbar>
                        <InfoBar user={this.state.user} gridRef={this.gridRef} />
                        <Stack height={"100%"}>
                            <Grid ref={this.gridRef} />
                        </Stack>
                        <Footer></Footer>
                    </ThemeProvider>
                </div>
            );
        }
        else {
            if (this.state.user !== undefined) {
                return (<ThemeProvider theme={theme}><LoginWidget /></ThemeProvider>)
            }
            else {
                return (<Box display={"flex"} height={"100vh"} alignItems={"center"} justifyContent={"center"}><CircularProgress /></Box>)
            }
        }
    }

}

export default App;
