import GridComponent from "../SuperClasses/GridComponent";
import {
    Box,
    Card, CardContent,
    CardMedia,
    CircularProgress,
    Divider,
    IconButton,
    Paper,
    Stack,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { GET_NEWS_BY_ID, DEFAULT_FETCH_HEADERS } from "../config";
import InsertLinkIcon from '@mui/icons-material/InsertLink';

export default class NewsWidget extends GridComponent {
    constructor(props) {
        super(props);
        this.initComponent({ "type": "News", "title": "News" })
    }

    componentDidMount() {
        if (!this.promise) {
            const requestOptions = {
                method: 'POST',
                headers: { ...DEFAULT_FETCH_HEADERS().headers, 'Content-Type': 'application/json' },
                body: JSON.stringify({ "collection": "scraped_articles", "id": this.props["id"] })
            };
            this.promise = fetch(GET_NEWS_BY_ID, { ...requestOptions })
                // this.promise = fetch(BASE_URL+"/documents/news/"+ this.props["id"], DEFAULT_FETCH_HEADERS()) 
                .then((response) => response.json())
                .then((data) => this.setState({ newsData: data }))
                .catch((error) => this.setState({ error: error }));
        }
    }

    render() {
        if (this.state.newsData === undefined) {
            return (
                <Paper elevation={6} sx={{ width: "100%", height: "100%" }} style={{ overflow: "hidden", userSelect: "none" }}>
                    {this.renderTopBar()}
                </Paper>)
        }
        else {
            return (
                <Paper className="card">
                    {this.renderTopBar()}
                    <div className="card__body">
                        <div className="card__body-inner">
                            <Typography variant={"h6"} display={"block"}>{this.state.newsData.title}</Typography>
                            <Typography variant={"overline"} display={"block"}>{this.state.newsData.publisher}</Typography>
                            <Typography paragraph={true} />
                            <Typography align={"left"} alignSelf={"flex-start"} variant={"body1"} display={"inline-block"} gutterBottom>{this.state.newsData.text}</Typography>
                        </div>
                    </div>
                </Paper>)
        }
    }
}