import React from "react";
import GridComponent from "../SuperClasses/GridComponent";
import {
    Box,
    CircularProgress,
    Paper,
    Stack,
} from "@mui/material";
import { GET_EP, DEFAULT_FETCH_HEADERS } from "../config";
import { Page } from 'react-pdf/dist/esm/entry.webpack5'
import { PDFWidget } from "./PDFWidget";
import {DocumentChatWidget} from "./DocumentChatWidget";


export default class EPDocumentWidget extends GridComponent {
    constructor(props) {
        super(props);
        this.initComponent({ "type": "EPWidget", "title": "European Parliament Document" })
        this.mdbExpanded = false;
    }

    componentDidMount() {
        this.setState({ "currentTab": 0 })
        if (!this.promise) {
            const requestOptions = {
                method: 'POST',
                headers: { ...DEFAULT_FETCH_HEADERS().headers, 'Content-Type': 'application/json' },
                body: JSON.stringify({ "_id": this.props["id"] })

            };
            // this.promise = fetch(BASE_URL+"/documents/ep/"+ this.props["id"], DEFAULT_FETCH_HEADERS())
            this.promise = fetch(GET_EP, { ...requestOptions })
                .then((response) => response.json())
                .then((data) => this.setState({ documentData: data }))
                .catch((error) => this.setState({ error: error }));

        }
    }

    onOpenPDFClick = (event) => {
        this.state.parentGrid.createNewComponent({ w: 6, h: 6, component: PDFWidget, componentArgs: { "pdf": this.state.documentData.pdf } })
    }


    onPDFLoadSuccess = ({ numPages }) => {
        this.setState({ "totalPages": numPages, "currentPage": 1 })
    }

    renderPDFPages = () => {
        let pages = []
        for (let i = 1; i <= this.state.totalPages; i++) {
            pages.push(<Page pageNumber={i} />)
        }
        return pages;
    }

    onPageChange = (event, value) => {
        this.setState({ "currentPage": value })
    }

    onTabChange = (event, value) => {
        this.setState({ "currentTab": value })
    }

    onChatBtnClick = (event) => {
        this.state.parentGrid.createNewComponent({ w: 12, h: 8, component: DocumentChatWidget, componentArgs: { documents: [{ id: this.props["id"], type: "European Parliament", title: this.state.documentData.title }] } })
    }

    renderCommittees = () => {

        if (!(this.state.documentData.doc.committees)) {
            return
        }
        else {
            let resorts = []
            this.state.documentData.doc.committees.forEach(e => {
                resorts.push(
                    <div key={e} className="meta">
                        <div className="meta__title">{resorts.length === 0 ? "Related Committees:" : ""}</div>
                        <div className="meta__data">{e}</div>
                    </div>
                )
            })
            return resorts
        }
    }

    render() {
        if (this.state.documentData === undefined || this.state.documentData.doc === undefined) {
            return (
                <Paper className={"card"} elevation={0} >
                    <Stack height={"100%"} overflow={"hidden"}>
                        {this.renderTopBar()}
                        <Box display={"flex"} height={"75%"} alignItems={"center"} justifyContent={"center"}><CircularProgress /></Box>
                    </Stack>
                </Paper>)
        }
        else {
            return (
                <Paper elevation={0} className={"card"} >
                    {this.renderTopBar()}
                    <div className="document-title">
                        <h1 className="document-title__title">{this.state.documentData.doc.title}</h1>
                        <div className="document-title__subtitle">Document</div>
                    </div>
                    <div className="card__scroll-area">
                        <div className="card-section">
                            <div className="card-section__header">
                                <div>
                                    <h3 className="card-section__title">Document Information</h3>
                                </div>
                                <div>
                                    <div className="button" onClick={this.onOpenPDFClick}>Open Document</div>
                                </div>
                            </div>
                            <div className="card-section__header">
                                <div>
                                    <h3 className="card-section__title">Analyze</h3>
                                </div>
                                <div>
                                    <div className="button" onClick={this.onChatBtnClick}>Chat with AI</div>
                                </div>
                            </div>
                            <div className="card-section__body">
                                <div className="meta">
                                    <div className="meta__title">EP ID</div>
                                    <div className="meta__data">{this.state.documentData.doc.ep_id}</div>
                                </div>
                                <div className="meta">
                                    <div className="meta__title">Text Qualifier</div>
                                    <div className="meta__data">{this.state.documentData.doc.ep_text_id}</div>
                                </div>
                                <div className="meta">
                                    <div className="meta__title">Date</div>
                                    <div className="meta__data">{this.state.documentData.doc.publish_date}</div>
                                </div>
                                <div className="meta">
                                    <div className="meta__title">Author</div>
                                    <div className="meta__data">{this.state.documentData.doc.author}</div>
                                </div>
                                {this.renderCommittees()}
                                <div className="meta">
                                    <div className="meta__title">External Link</div>
                                    <a href={this.state.documentData.doc.pdf_url} target="_blank" download>Go to Source PDF</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Paper>
            )
        }
    }
}
