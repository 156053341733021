import React, { Component } from "react";
import Chart from "react-apexcharts";

export default class BarChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    id: "basic-bar",
                    offsetX: 0,
                    offsetY: 0,
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        dataLabels: {
                            position: 'top',
                        },
                    }
                },
                xaxis: {
                    categories: this.props.categories,
                    labels: {
                        rotate: -30
                    },
                }
            },
            series: [
                {
                    name: this.props.seriesName,
                    data: this.props.values
                }
            ]
        };
    }

    render() {
        return (
            <div className="barChart">
                <div className="row">
                    <div className="mixed-chart">
                        <Chart
                            options={this.state.options}
                            series={this.state.series}
                            type="bar"
                            width="96%"
                            height="300"
                        />
                    </div>
                </div>
            </div>
        );
    }
}