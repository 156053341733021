import GridComponent from "../SuperClasses/GridComponent";
import {Button, CircularProgress, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Typography} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import React, { ChangeEvent } from "react";
import { DOCUMENT_SERVICE } from "../config";
import { PDFWidget } from "./PDFWidget";
type WidgetState = "initial" | "loading" | "success" | "error" | "fileSelected";

const DOCUMENT_TYPES = [
    { label: "Bundestag", value: "bundestag-printed-matters" },
    { label: "European Parliament", value: "ep-documents" },
    { label: "Book", value: "books" },
]

export default class UploadWidget extends GridComponent {
    state: {
        widgetState: WidgetState, 
        file: File | undefined,
        fileId: string | undefined,
        documentType: "bundestag-printed-matters" | "ep-documents" | "books" | "",
        uploadResponse: {json: any, status: number} | undefined,
        [key: string]: any
    };

    constructor(props: any) {
        super(props);
    
        this.state = {
            ...props,
            file: null,
            widgetState: "initial",
            documentType: "",
        };
        this.initComponent({ type: "upload", title: "upload." });
    }

    isUploadEnabled = () => {
        return this.state.widgetState === "fileSelected" 
        && !!this.state.file
        && !!this.state.documentType;
    }

    isSelectFileEnabled = () => {
        return this.state.widgetState !== "loading"
    }

    handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event?.target?.files?.[0];
        this.setState({ file: selectedFile });
        this.setState({ widgetState: "fileSelected" });
    };

    handleDropdownChange = (event: SelectChangeEvent) => {
        const selectedDocumentType = event?.target?.value;
        this.setState({ documentType: selectedDocumentType });
    }

    handlePlusButtonClick = () => {
        // Trigger file input click when IconButton is clicked
        const fileInput = document.getElementById('fileInput');
        if(!fileInput){
            console.error("Could not find file input element");
            return;
        }
        fileInput.click();
    };

    uploadFile = async () => {
        const { file } = this.state;
    
        if (!file) {
            console.error('No file selected.');
            return;
        }
        if (!this.state.documentType) {
            console.error('No document type selected.');
            return;
        }
        this.setState({ widgetState: "loading" });

        const formData = new FormData();
        formData.append('file', file);

        try {
            const uploadResponse = await fetch(`${DOCUMENT_SERVICE}/${this.state.documentType}/`, {
                method: 'POST',
                body: formData
            });
            const uploadResponseJson = await uploadResponse.json();
            this.setState({
                uploadResponse: {
                    json: {...uploadResponseJson}, 
                    status: uploadResponse.status
                }
            });

            if (uploadResponse.ok) {
                this.setState({ widgetState: "success" });
                this.setState({ fileId: uploadResponseJson._id });
                console.log('File uploaded successfully:', uploadResponseJson);
            } else {
                console.error('Error uploading file:', uploadResponseJson);
                this.setState({ widgetState: "error" });
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            this.setState({ widgetState: "error" });
        } 
        
    };

    showPDF = async () => {
        const { fileId } = this.state;
        if (!fileId) {
            console.error('No file selected.');
            return;
        }
        this.state.parentGrid.createNewComponent({ w: 6, h: 6, component: PDFWidget, componentArgs: { pdfUrl: `${DOCUMENT_SERVICE}/books/${fileId}/pdf` } })
       
    }

    render_main_content() {
        
        return ( 
            <div>
                {this.renderDropdown()}
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:"15px"}}>
                    <IconButton onClick={this.handlePlusButtonClick} aria-label="Select File" disabled={!this.isSelectFileEnabled()}>
                        <AddCircleOutlineIcon sx={{fontSize: "60px"}}/>
                    </IconButton>
                    <input id="fileInput" type="file" accept=".pdf" onChange={this.handleFileChange} hidden />
                </div>
                {this.render_state_based_text()}
                {this.renderBottomRightButton()}
            </div>)
    }
    
    // If the upload has not happened yet, should be the "upload" button. If the upload was successful, we want to show the pdf instead.
    renderBottomRightButton() {
        if(this.state.widgetState === "success"){
            return(
                <div style={{ position: 'fixed', bottom: '20px', right: '20px' }}>
                    <button className="button is--primary" onClick={this.showPDF}>Show PDF</button>
                </div>
            )
        }
        return( 
            <div style={{ position: 'fixed', bottom: '20px', right: '20px' }}>
                <Button onClick={this.uploadFile} variant="contained" disabled={!this.isUploadEnabled()} className="button is--primary"  sx={{ textTransform: 'none'}}>
                    Upload
                </Button>
            </div>)
    }

    render_state_based_text() {
        let content
        switch (this.state.widgetState) {
            case "initial":
                content = (
                    <div>
                        <Typography variant="body1">Select file.</Typography>
                    </div>);
                break;
            case "fileSelected":
                content = (<Typography noWrap variant="body1">{this.state.file?.name}</Typography>);
                break;
            case "loading":
                content = <CircularProgress />;
                break;
            case "success":
                content = (
                        <Typography variant="body1">File uploaded successfully! </Typography>);
                break;
            case "error":
                if(this.state.uploadResponse && this.state.uploadResponse.status === 400){
                    let errorMesasge = this.state.uploadResponse.json.detail;
                    if(!errorMesasge){
                        errorMesasge = "Unknown error uploading file";
                    }
                    content = <Typography variant="body1" color={"red"}>{errorMesasge}</Typography>
                    break;

                }
                content = <Typography variant="body1" color={"red"}>Unknown error uploading file</Typography>
                break;
        }

        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop:"3%"}}>
                {content}
            </div>
        )
        
    }

    renderDropdown() {
        return(
            <FormControl fullWidth size="small" className="dropdown-form">
                <InputLabel>Select the type of document to upload</InputLabel>
                    <Select
                        value={this.state.documentType}
                        label="Select the type of document to upload"
                        onChange={(e) => this.handleDropdownChange(e)}
                        disabled={!this.isSelectFileEnabled()}
                        >
                        {DOCUMENT_TYPES.map( el => <MenuItem value={el.value}>{el.label}</MenuItem>)}
                    </Select>
            </FormControl>
        )
    }

    render() {
        return(
            <Paper className="card">
                {this.renderTopBar()}
                <div className="card__body">
                    <div className="card__body-inner">
                        <div className="card__body-inner">{this.render_main_content()}</div>
                    </div>
                </div>
            </Paper>)
    }
}