import GridComponent from "../SuperClasses/GridComponent";
import { Box, CircularProgress, Divider, IconButton, Paper, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { TwitterTweetEmbed } from "react-twitter-embed";
import { GET_TWEET, DEFAULT_FETCH_HEADERS } from "../config";
import { Tweet } from 'react-twitter-widgets'

export default class TweetWidget extends GridComponent {
    constructor(props) {
        super(props);
        this.initComponent({ "type": "Tweet", "title": "Tweet" })
    }

    componentDidMount() {
        if (!this.promise) {
            const requestOptions = {
                method: 'POST',
                headers: { ...DEFAULT_FETCH_HEADERS().headers, 'Content-Type': 'application/json' },
                body: JSON.stringify({ "_id": this.props["id"] })
            };
            this.promise = fetch(GET_TWEET, { ...requestOptions })
                .then((response) => response.json())
                .then((data) => this.setState({ data: data }))
                .catch((error) => this.setState({ error: error }));

        }
    }

    render_main_content() {
        if (this.state.data) {
            return <Tweet tweetId={this.state.data["tweet_id"]} />
        }
        else {
            return <CircularProgress />
        }
    }

    render() {
        return (
            <Paper className="card">
                {this.renderTopBar()}
                <div className="card__body">
                    <div className="card__body-inner">
                        {this.render_main_content()}
                    </div>
                </div>
            </Paper>
        )

    }
}