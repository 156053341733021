import { getAuth, signInWithPopup, signInWithEmailAndPassword, GoogleAuthProvider, sendPasswordResetEmail, OAuthProvider } from "firebase/auth";
import { initializeApp } from "firebase/app";
import React from "react";
import Cookies from 'js-cookie'


const firebaseConfig = {
    apiKey: "AIzaSyCO2mWDYjSV6afTdMxCFAhoYJo_sb6zyzA",
    authDomain: "inq-app-402508.firebaseapp.com",
    projectId: "inq-app-402508",
    storageBucket: "inq-app-402508.appspot.com",
    messagingSenderId: "460212544539",
    appId: "1:460212544539:web:66ccca029c8930bc22c4be"
};
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();


export function signInWithGoogle() {
    signInWithPopup(auth, provider)
        .catch((error) => {

        });
}

export function signInWithMS() {
    let msProvider = new OAuthProvider('microsoft.com');
    msProvider.setCustomParameters({
        prompt: "consent",
        tenant: "6047730d-acb9-4ab0-b32b-ddbd95c5a1ed",
    })
    signInWithPopup(auth, msProvider)
        .then((result) => {
            // User is signed in.
            // IdP data available in result.additionalUserInfo.profile.

            // Get the OAuth access token and ID Token
            const credential = OAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;
            const idToken = credential.idToken;
        })
        .catch((error) => {
            console.log(error)
        });
}

export async function signInWithUser(mail, pass) {
    return await signInWithEmailAndPassword(auth, mail, pass).then(function () {
        return true
    }).catch((error) => {
        return false
    });
}

export function logOut() {
    auth.signOut()
        .then(function () {
            sessionStorage.setItem('auth', null);
            Cookies.remove('CookieConsent')
        })
        .catch(function (error) {

        });
}

export async function resetPassword(mail) {
    let result = undefined;
    await sendPasswordResetEmail(auth, mail)
        .then(function () {
            result = true
        })
        .catch(function (error) {
            result = false
        });
    return result
}
