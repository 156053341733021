let BASE_URL = "https://dashboard.perspectives.tools/api";
let SEARCH_NEWS = "https://europe-west3-inq-app-402508.cloudfunctions.net/search_news";
let GET_BUNDESTAG = "https://europe-west3-inq-app-402508.cloudfunctions.net/get_bundestag";
let SEARCH_DOCUMENTS = "https://europe-west3-inq-app-402508.cloudfunctions.net/search_documents";
let GET_TWEET = "https://europe-west3-inq-app-402508.cloudfunctions.net/get_tweet";
let GET_STAKEHOLDER = "https://europe-west3-inq-app-402508.cloudfunctions.net/get_stakeholder";
let VERIFY_TOKEN = "https://europe-west3-inq-app-402508.cloudfunctions.net/verify_token";
let GET_STAKEHOLDERS = "https://europe-west3-inq-app-402508.cloudfunctions.net/get_stakeholders";
let GET_DOCUMENTS = "https://europe-west3-inq-app-402508.cloudfunctions.net/get_documents";
let GET_NEWS_BY_ID = "https://europe-west3-inq-app-402508.cloudfunctions.net/get_news_by_id";
let GET_EP = "https://europe-west3-inq-app-402508.cloudfunctions.net/get_ep";
let GET_NEWSLETTER = "https://europe-west3-inq-app-402508.cloudfunctions.net/get_inq_newsletter";
let CHAT_BOT_URL = "https://inq-ai-chat-backend-rwovqhh25a-ey.a.run.app"
let EXTRACT_ARTICLE = "https://inq-summarizer-app-rwovqhh25a-ey.a.run.app/extract_article"
let YT_TRANSCRIPT = "https://inq-summarizer-app-rwovqhh25a-ey.a.run.app/yt_transcript"
let DOCUMENT_SERVICE = "https://documents-library-api-rwovqhh25a-ey.a.run.app"

//Hoc added
let PDF_SUMMARY = "https://inq-summarizer-app-rwovqhh25a-ey.a.run.app/summarize_pdf"
let GET_NEWS_MEDIAMONITOR = "https://mediamonitor-rwovqhh25a-ey.a.run.app/get_news"
let GET_NEWS_FILTRED_MEDIAMONITOR = "https://mediamonitor-rwovqhh25a-ey.a.run.app/filter_articles"

let GET_SCHEMA_SEARCH = "https://mediamonitor-rwovqhh25a-ey.a.run.app/schema_search"
let GET_SCHEMA_SEARCH_CSV ="https://mediamonitor-rwovqhh25a-ey.a.run.app/get_csv"

let DEFAULT_FETCH_HEADERS = () => {
    return (({ headers: { "Authorization": "Bearer " + sessionStorage.getItem('auth') } }))
}

export { BASE_URL, EXTRACT_ARTICLE, YT_TRANSCRIPT, PDF_SUMMARY, SEARCH_NEWS, GET_BUNDESTAG, SEARCH_DOCUMENTS, GET_TWEET, GET_STAKEHOLDER, VERIFY_TOKEN, GET_STAKEHOLDERS, GET_DOCUMENTS, GET_NEWS_BY_ID, GET_EP, GET_NEWSLETTER, CHAT_BOT_URL, DOCUMENT_SERVICE, DEFAULT_FETCH_HEADERS, GET_NEWS_MEDIAMONITOR, GET_NEWS_FILTRED_MEDIAMONITOR, GET_SCHEMA_SEARCH, GET_SCHEMA_SEARCH_CSV }
