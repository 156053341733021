import React from "react";
import {
  Modal,
  Paper,
  Stack,
  TextField,
  CircularProgress,
  Box,
  Alert,
  Button,
  Chip,
  IconButton,
} from "@mui/material";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CloseIcon from "@mui/icons-material/Close";
import * as dayjs from "dayjs";
import _ from "lodash";

import GridComponent from "../SuperClasses/GridComponent";
import { FilterModal } from "../InCardWidgets/FilterModal";
import PDataGrid from "../InCardWidgets/PDataGrid";
import { SEARCH_DOCUMENTS, DEFAULT_FETCH_HEADERS } from "../config";
import {DocumentChatWidget} from "./DocumentChatWidget";

export const getColumnWidth = (data, accessor, headerText) => {
  const cellLength = Math.max(
    ...data.map((row) => {
      let value = "";

      if (typeof accessor === "string") {
        value = _.get(row, accessor);
      } else {
        value = accessor(row);
      }

      if (typeof value === "number") return value.toString().length;
      return (value || "").length;
    }),
    headerText.length
  );

  const magicSpacing = data == "id" ? 1 : 12;
  return cellLength * magicSpacing;
};

export default class AnalyserWidget extends GridComponent {
  constructor(props) {
    super(props);
    let today = dayjs().format("DD/MM/YYYY");
    this.today = today;
    this.state = {
      ...props,
      showFilterModal: false,
      filters: {
        general: { date: { enabled: false, startDate: today, endDate: today } },
        bundestag: { enabled: true },
        twitter: { enabled: false },
        ep: { enabled: true },
        books: { enabled: true },
        news: { enabled: false, period: 0, maxArticles: 10 },
      },
      results: [],
      searchLoader: false,
      error: null,
      keyword: "",
      initial: true,
      showFilters: false,
      selectedDocuments: [],
    };
    this.displayStartDate = undefined;
    this.displayEndDate = undefined;
    this.displayNewsPeriod = "Last Hour";
    this.optionTab = 0;
    this.filtersActive = false;
    this.newsPeriodMap = new Map()
      .set("Last Hour", 0)
      .set("Last Day", 1)
      .set("Last Week", 2)
      .set("Last Month", 3)
      .set("Last Year", 4)
      .set("All time", 5);
    this.initComponent({ type: "Analyser", title: "Analyser" });
  }

  componentDidUpdate() {
    if (!this.promise && this.state.searchLoader) {
      const requestOptions = {
        method: "POST",
        headers: {
          ...DEFAULT_FETCH_HEADERS().headers,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          keyword: this.state.keyword,
          filters: this.state.filters,
        }),
      };

      this.promise = fetch(SEARCH_DOCUMENTS, { ...requestOptions })
        .then((response) => response.json())
        .then((data) =>
          this.setState({ results: data["objects"], searchLoader: false })
        )
        .catch((error) => this.setState({ error: error, searchLoader: false }));
    }
  }

  onKeyDown = (e) => {
    if (e.key === "Enter") {
      this.onClickGo();
    }
  };

  onClickGo = (event) => {
    this.setState((prev) => ({ ...prev, searchLoader: true, initial: false }));
  };

  onModalClose = () => {
    this.setState((prev) => ({ ...prev, showFilterModal: false }));
  };

  updateFilter = (filters) => {
    let backup_filters = {
      general: {
        date: { enabled: false, startDate: this.today, endDate: this.today },
      },
      bundestag: { enabled: true },
      twitter: { enabled: false },
      ep: { enabled: true },
      books: { enabled: true },
      news: { enabled: false, period: 0, maxArticles: 10 },
    };

    this.filtersActive = !_.isEqual(filters, backup_filters);
    this.setState((prevState) => ({ filters: filters }));
  };

  onFilterClick = (event) => {
    this.optionTab = 1;
    this.setState((prev) => ({ ...prev, showFilterModal: true }));
  };

  handleAddBtnClick = (id, type, title) => {
    this.setState((prev) => ({
      ...prev,
      selectedDocuments: [...prev.selectedDocuments, { id: id, type: type, title: title }],
    }));
  };

  handleDocumentDelete = (id) => {
    this.setState((prev) => ({
      ...prev,
      selectedDocuments: prev.selectedDocuments.filter((el) => el.id !== id),
    }));
  };

  onChatBtnClick = () => {
    this.state.parentGrid.createNewComponent({ w: 12, h: 8, component: DocumentChatWidget, componentArgs: { documents: this.state.selectedDocuments } })
  }

  renderColumnValue = (params, data) => {
    if (data == "id") {
      return (
        <Button
          variant={"contained"}
          size={"small"}
          onClick={() => this.handleAddBtnClick(params.row["id"], params.row["Source"], params.row["Title"])}
          disabled={this.state.selectedDocuments.find(el => el.id === params.row["id"])}
        >
          + Add
        </Button>
      );
    } else {
      return params.row[data];
    }
  };

  setupColumns = () => {
    let cols = [];
    if (this.state.results.length > 0) {
      Object.keys(this.state.results[0]).forEach((data) => {
        cols.push({
          field: data,
          headerClassName: "datagrid_headers",
          headerName: data == "id" ? "Action" : data,
          accessor: "accessor",
          editable: false,
          hide: data == "Stakeholder ID",
          maxWidth:
            data == "Type" || data == "id"
              ? 150
              : getColumnWidth(this.state.results, data, data),
          flex: 1,
          renderCell: (params) => this.renderColumnValue(params, data),
        });
      });
    }
    return cols;
  };

  render() {
    return (
      <React.Fragment>
        <Modal open={this.state.showFilterModal}>
          <div>
            <FilterModal
              tab={this.optionTab}
              onClose={this.onModalClose}
              onFilterSet={this.updateFilter}
              filters={this.state.filters}
              onlyDocuments={true}
            />
          </div>
        </Modal>
        <Paper
          className={"card"}
          elevation={1}
          sx={{ width: "100%", height: "100%" }}
          style={{ overflow: "auto" }}
        >
          <Stack sx={{ padding: "5px 15px 5px 15px" }} direction="row"
              className={"drag-handle card__header"}>
              <div className={"card__title"}>{this.properties.title}</div>
              <div className={"card_controls"}>
                  <IconButton className={"control-button"} size={"small"}>
                      <HelpOutlineIcon className={"control-button__inner"} />
                  </IconButton>
                  <IconButton
                      className={"control-button " + (this.state.showFilters === true ? "is--active" : "")}
                      size={"small"}
                      onClick={() => (this.setState((prev) => ({
                          ...prev,
                          showFilters: !this.state.showFilters
                      })))}>
                      <FilterAltOutlinedIcon className={"control-button__inner"} />
                  </IconButton>

                  <IconButton className={"control-button"} size={"small"} onClick={this.onCloseClicked}>
                      <CloseIcon className={"control-button__inner"} />
                  </IconButton>
              </div>
          </Stack>
          <div className={"card__body"}>
            <div className={"card__body-inner"}>
              <Stack
                className={"search"}
                marginTop={0.5}
                direction={"row"}
                spacing={1}
              >
                <TextField
                  className={"search__input"}
                  type="search"
                  fullWidth
                  variant="outlined"
                  label={"Search for anything..."}
                  onKeyDown={this.onKeyDown}
                  onChange={(event) =>
                    this.setState({ keyword: event.target.value })
                  }
                />
                <div
                  className={"button is--primary"}
                  variant={"contained"}
                  color={"primary"}
                  onClick={this.onClickGo}
                >
                  Search
                </div>
                <div
                  className={
                    "search__filter button is--secondary w-embed " +
                    (this.filtersActive ? "is--active" : "")
                  }
                  onClick={() => this.onFilterClick()}
                >
                  <svg
                    className={"button__icon"}
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
                      stroke="#344054"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  Filter
                </div>
              </Stack>
            </div>

            {this.state.searchLoader && (
              <Box
                display={"flex"}
                height={"75%"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress />
              </Box>
            )}

            {!this.state.searchLoader &&
              !this.state.initial &&
              this.state.error && (
                <Box
                  display={"flex"}
                  height={"75%"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Alert variant="outlined" severity="error">
                    Sorry, we could not fetch your data (Server Error)
                  </Alert>
                </Box>
              )}

            {!this.state.searchLoader &&
              !this.state.initial &&
              !this.state.error &&
              (this.state.results.length === 0 ? (
                <Box
                  display={"flex"}
                  height={"75%"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Alert variant="outlined" severity="warning">
                    Sorry, we could not get any results for your query
                  </Alert>
                </Box>
              ) : (
                <Box height={"calc(100% - 280px)"}>
                  <PDataGrid
                    rows={this.state.results}
                    columns={this.setupColumns()}
                    parent={this}
                    showFilters={this.state.showFilters}
                  />
                </Box>
              ))}

            {this.state.selectedDocuments.length > 0 && (
              <>
                {console.log("selectedDocuments", this.state.selectedDocuments)}
                <Box className={"chat-doc-list"}>
                  {this.state.selectedDocuments.map((el) => (
                    <Chip
                      className="chat-doc-chip"
                      key={el.id}
                      title={el.title}
                      label={el.title}
                      variant="outlined"
                      onDelete={() => this.handleDocumentDelete(el.id)}
                    />
                  ))}
                </Box>
                <div className="footer-btn">
                  <Button variant={"contained"} onClick={() => this.onChatBtnClick()}>Start AI Chat</Button>
                </div>
              </>
            )}
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
