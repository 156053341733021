import React, {Component} from "react";
import {Autocomplete, FormControlLabel, IconButton, Switch, TextField} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CloseIcon from "@mui/icons-material/Close";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers";

export class FilterModal extends Component{
    constructor(props) {
        super(props);
        this.state = {currentTab: this.props.tab, filters: this.props.filters}
        this.newsPeriodMap = new Map().set("Last Hour", 0).set("Last Day", 1).set("Last Week", 2).set("Last Month", 3).set("Last Year", 4).set("All time", 5)
        this.displayNewsPeriod = new Map([...this.newsPeriodMap.entries()].map(([key, value]) => ([value, key]))).get(this.state.filters.news.period)
    }

    handleClose = event => {
        this.props.onClose(event.target.value)
    }

    onSourceEnabledChange = (event, value, key) =>
    {
        this.setState( prevState =>
            ({filters: {...prevState.filters,[key]: {...prevState.filters[key], enabled:value}}}))
    }

    applyFilter = event =>
    {
        this.props.onFilterSet(this.state.filters)
        this.handleClose(event)
    }

    onStartDateChange = (event) =>
    {
        this.displayStartDate = event;
        this.setState(prevState=>({filters:{...prevState.filters,
                general: {...prevState.filters.general,
                    ["date"]: {...prevState.filters.general.date,
                        startDate:String(event.getDate()).padStart(2, '0') +"/"+ String(event.getMonth()+1).padStart(2, '0')+"/"+String(event.getFullYear())}}}}))

    }

    onEndDateChange = (event) =>
    {
        this.displayEndDate = event;
        this.setState(prevState => ({filters:{...prevState.filters,
                general: {...prevState.filters.general,
                    ["date"]: {...prevState.filters.general.date,
                        endDate:String(event.getDate()).padStart(2, '0') +"/"+ String(event.getMonth()+1).padStart(2, '0')+"/"+String(event.getFullYear())}}}}))
    }

    onFilterTimeChange = (event) =>
    {
        this.setState(prevState=> ({filters:{...prevState.filters,
                general: {...prevState.filters.general,["date"]: {...prevState.filters.general.date, enabled:event.target.checked}}}}))
    }

    renderNewsOptions()
    {
        if(this.state.filters.news.enabled)
        {
            return(
                <div className={"news-options"}>
                    <div className="setting">
                        <div className="setting__label">Max. amount of articles</div>
                        <div className="setting__item">
                            <Autocomplete
                                disableClearable
                                className="setting__item"
                                size={"small"}
                                fullWidth={true}
                                sx={{ width: 210}}
                                defaultValue={this.displayNewsPeriod}
                                value={this.displayNewsPeriod}
                                inputValue={this.displayNewsPeriod}
                                options={["Last Hour", "Last Day", "Last Week", "Last Month", "Last Year", "All time"]}
                                renderInput={(params) =><TextField {...params}/>}
                                onChange={(event,value) =>
                                {this.displayNewsPeriod = value;
                                    this.setState(prevState =>
                                        ({filters: {...prevState.filters,["news"]: {...prevState.filters.news, period: this.newsPeriodMap.get(value)}}}))}}
                            />
                        </div>
                    </div>
                    <div className="setting">
                        <div className="setting__label">Max. amount of articles</div>
                        <div className="setting__item">
                            <TextField
                                size={"small"}
                                value={this.state.filters.news.maxArticles}
                                type="number"
                                variant="outlined"
                                onChange={(event) => this.setState(prevState => ({filters: {...prevState.filters,["news"]: {...prevState.filters.news, maxArticles: event.target.value}}}))}
                            />
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderTab = () =>
    {
        if(this.state.currentTab === 0) {
            return(

                <div className="filter__body">
                    <div className="setting">
                        <div className="setting__label">Limit Timeframe</div>
                        <div className="setting__item">
                            <FormControlLabel control={<Switch color={"primary"}/>}
                                              checked={this.state.filters.general.date.enabled}
                                              defaultChecked={this.state.filters.general.date.enabled}
                                              label={"Limit Timeframe"}
                                              onChange={this.onFilterTimeChange}/>
                        </div>
                    </div>
                    <div className="setting">
                        <div className="setting__label">Start Date</div>
                        <div className="setting__item">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    size={"small"}
                                    inputFormat="dd/MM/yyyy"
                                    disableFuture
                                    disabled={!this.state.filters.general.date.enabled}
                                    value={this.displayStartDate}
                                    label="Start Date"
                                    openTo="day"
                                    onChange={this.onStartDateChange}
                                    renderInput={(params) => <TextField  size={"small"}{...params} />}
                                />
                            </LocalizationProvider>

                        </div>
                    </div>
                    <div className="setting">
                        <div className="setting__label">End Date</div>
                        <div className="setting__item">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    size={"small"}
                                    disableFuture
                                    inputFormat="dd/MM/yyyy"
                                    disabled={!this.state.filters.general.date.enabled}
                                    value={this.displayEndDate}
                                    onChange={this.onEndDateChange}
                                    label="End Date"
                                    openTo="day"
                                    renderInput={(params) => <TextField size={"small"} {...params} />}

                                />
                            </LocalizationProvider>

                        </div>
                    </div>
                </div>)
        }
        if(this.state.currentTab === 1)
        {
            return(
                <div className="filter__body">
                    <div className="setting">
                        <div className="setting__label">Enable Bundestag/Bundesrat</div>
                        <div className="setting__item">
                            <FormControlLabel control={<Switch color={"primary"}/>}
                                              checked={this.state.filters.bundestag.enabled}
                                              defaultChecked={this.state.filters.bundestag.enabled}
                                              onChange={(event,value) => this.onSourceEnabledChange(event, value, "bundestag")}/>
                        </div>
                    </div>
                    <div className="setting">
                        <div className="setting__label">Enable European Parliament</div>
                        <div className="setting__item">
                            <FormControlLabel control={<Switch color={"primary"}/>}
                                              checked={this.state.filters.ep.enabled}
                                              defaultChecked={this.state.filters.ep.enabled}
                                              onChange={(event,value) => this.onSourceEnabledChange(event, value, "ep")}/>
                        </div>
                    </div>
                    <div className="setting">
                        <div className="setting__label">Enable Library</div>
                        <div className="setting__item">
                            <FormControlLabel control={<Switch color={"primary"}/>}
                                              checked={this.state.filters.books.enabled}
                                              defaultChecked={this.state.filters.books.enabled}
                                              onChange={(event,value) => this.onSourceEnabledChange(event, value, "books")}/>
                        </div>
                    </div>
                    {!this.props.onlyDocuments && (
                      <>
                    <div className="setting">
                        <div className="setting__label">Enable Twitter</div>
                        <div className="setting__item">
                            <FormControlLabel
                                control={<Switch color={"primary"}/>}
                                checked={this.state.filters.twitter.enabled}
                                defaultChecked={this.state.filters.twitter.enabled}
                                onChange={(event,value) => this.onSourceEnabledChange(event, value, "twitter")}/>
                        </div>
                        <div className="setting">
                            <div className="setting__label">Enable News</div>
                            <div className="setting__item">
                                <FormControlLabel control={<Switch color={"primary"}/>}
                                                  checked={this.state.filters.news.enabled}
                                                  defaultChecked={this.state.filters.news.enabled}
                                                  onChange={(event,value) => this.onSourceEnabledChange(event, value, "news")}/>
                                </div>
                            </div>
                        </div>
                        {this.renderNewsOptions()}
                      </>
                    )}
                </div>)
        }
    }

    render() {
        return(
            <div className="modal-wrapper">
                <div className="modal">
                    <div className="modal__header">
                        <div className="modal__title">
                            <div className="modal__title-icon w-embed">
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                     xmlns="https://www.w3.org/2000/svg">
                                    <path d="M6 12.5H18M3 6.5H21M9 18.5H15" stroke="currentColor" strokeWidth="1.33333"
                                          strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </div>
                            <div>Advanced Search Filter</div>
                        </div>
                        <div className="card_controls">
                            <IconButton  className={"control-button"} size={"small"} >
                                <HelpOutlineIcon/>
                            </IconButton>
                            <IconButton  className={"control-button"}  size={"small"} onClick={this.handleClose}>
                                <CloseIcon className={"control-button__inner w-embed"}/>
                            </IconButton>
                        </div>
                    </div>
                    <div className="modal__section">
                        <div className="filter">
                            <div className="filter__tabs">
                                <div className="filter__label">Filter by:</div>
                                <div className={"filter__tab " + (this.state.currentTab === 0 ? "is--active" : "")}
                                     onClick={() => this.setState((prev) => ({...prev, currentTab:0}))}>Timeframe</div>
                                <div className={"filter__tab " + (this.state.currentTab === 1 ? "is--active" : "")}
                                     onClick={() => this.setState((prev) => ({...prev, currentTab:1}))}>Sources</div>
                            </div>
                            {this.renderTab()}

                        </div>
                    </div>
                    <div className="modal__footer">
                        <div className="modal__actions">
                            <div className="modal__actions-item">
                                <div onClick={this.handleClose} className="button is--block">Cancel</div>
                            </div>
                            <div className="modal__actions-item">
                                <div className="button is--primary is--block" onClick={this.applyFilter}>Apply Filter</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
