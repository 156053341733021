import { Responsive, WidthProvider } from "react-grid-layout";
import React from "react";
import Search from "../StandaloneWidgets/Search";
import NewsletterWidget from "../StandaloneWidgets/NewsletterWidget";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';
import SandboxWidget from "../StandaloneWidgets/SandboxWidget";
const ResponsiveGridLayout = WidthProvider(Responsive);

export class Grid extends React.Component {

    constructor(props) {
        super(props);
        this.state = { items: [] };

    }

    componentDidMount() {
        let key = uuidv4();
        let searchItem = {
            i: key,
            key: key,
            x: 0,
            y: 0,
            w: 7,
            h: 1.5,
            minW: 2,
            minH: 3,
            component: Search,
        };

        key = uuidv4();
        let newsItem = {
            i: key,
            key: key,
            x: 7,
            y: 0,
            w: 5,
            h: 6,
            minW: 2,
            minH: 3,
            component: NewsletterWidget,
        };


        this.setState({ items: [searchItem, newsItem] });
    }

    createNewComponent = (props) => {
        let idealPos = 0;
        if (this.state.items.length > 0) {
            let lastItem = this.state.layout[this.state.layout.length - 1]
            idealPos = (lastItem.x + lastItem.w) + props.w > 12 ? 0 : (lastItem.x + lastItem.w)
        }

        let key = uuidv4();
        let newItem = {
            i: key,
            key: key,
            x: idealPos,
            y: 0,
            w: props.w,
            h: props.h,
            minW: 2,
            minH: 3,
            component: props.component,
            args: props.componentArgs
        }
        this.setState({ items: this.state.items.concat(newItem) })

    }

    createElement(el) {
        return (
            <div key={el.i} data-grid={{ x: el.x, y: el.y, w: el.w, h: el.h, minW: el.minW, minH: el.minH }}>
                <el.component parentGrid={this} gridRepresentation={el} {...el.args} />
            </div>
        );
    }

    onLayoutChange = (layout) => {
        this.setState({ layout: layout });
    }

    onRemoveItem = (i) => {
        this.setState({ items: _.reject(this.state.items, { i: i }) });
    }

    render() {
        return (
            <ResponsiveGridLayout
                onLayoutChange={this.onLayoutChange}
                draggableHandle=".drag-handle"
                rowHeight={96}
                cols={{ lg: 14, md: 12, sm: 9, xs: 6, xxs: 3 }}
                breakpoints={{ lg: 1920, md: 1280, sm: 992, xs: 768, xxs: 0 }}
                columns={12}
                margin={[24, 24]}
                verticalCompact={false}
                preventCollision={false}
                width={"100%"}>
                {_.map(this.state.items, el => this.createElement(el))}
            </ResponsiveGridLayout>
        );
    }
}
