import React, { Component } from "react";
import Chart from "react-apexcharts";

export default class Sparkline extends Component {
    constructor(props) {
        super(props);

        this.state = {
            parent: props.parent,
            options: {
                chart: {
                    id: "basic-timeseries",
                    type: "area",
                    stacked: "false",
                    offsetX: 0,
                    offsetY: 0,
                    sparkline: {enabled: true},
                    zoom: {
                        autoScaleYaxis: true
                    },
                    events:{
                        click: function(event, chartContext, config) {
                            if (props.onClickHandler !== undefined)
                            {
                                props.onClickHandler(event, chartContext, config)
                            }
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                axisTicks: {
                    show: true
                },
                markers: {
                    size: 0,
                    style: 'hollow',
                },
                xaxis: {
                    type: 'datetime',
                    tickAmount: 1,
                    categories: this.props.categories,
                    labels: {

                        rotate: -30,
                    },
                },
                tooltip: {
                    x: {
                        format: "MMM yyyy"
                    },
                    fixed: {
                        position: 'topRight',
                    },
                }
            },
            series: [
                {
                    name: this.props.seriesName,
                    data: this.props.values
                }
            ]
        };
    }

    render() {
        return (
            <div className="sparkLine">
                <div className="row">
                    <div className="mixed-chart">
                        <Chart
                            options={this.state.options}
                            series={this.state.series}
                            type="area"
                            width="100%"
                            height="80vh"
                        />
                    </div>
                </div>
            </div>
        );
    }
}