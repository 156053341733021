import {
    Modal,
    Paper,
    Stack,
    TextField,
} from "@mui/material";
import React from "react";

import GridComponent from "../SuperClasses/GridComponent";
import SearchResultGrid from "./SearchResultGrid";
import { FilterModal } from "../InCardWidgets/FilterModal";
import _ from "lodash";

export default class Search extends GridComponent {

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.onClickGo();
        }
    }


    constructor(props) {
        super(props);
        let today = new Date();
        today = String(today.getDate()).padStart(2, '0') + "/" + String(today.getMonth() + 1).padStart(2, '0') + "/" + String(today.getFullYear())
        this.today = today
        this.state = {
            ...props, currentFilterTab: 0, showFilterModal: false,
            filters: {
                general: { date: { enabled: false, startDate: today, endDate: today } },
                bundestag: { enabled: true },
                twitter: { enabled: true },
                ep: { enabled: true },
                books: { enabled: true },
                news: { enabled: false, period: 0, maxArticles: 10 },
            }
        }
        this.displayStartDate = undefined;
        this.displayEndDate = undefined;
        this.displayNewsPeriod = "Last Hour"
        this.optionTab = 0;
        this.filtersActive = false;
        this.newsPeriodMap = new Map().set("Last Hour", 0).set("Last Day", 1).set("Last Week", 2).set("Last Month", 3).set("Last Year", 4).set("All time", 5)
        this.initComponent({ "type": "KeywordSearch", "title": "Keyword Search" })
    }

    componentDidMount() {
    }

    onClickGo = (event) => {
        if (this.state.keyword !== undefined) {
            this.state.parentGrid.createNewComponent({
                w: 6,
                h: 6,
                component: SearchResultGrid,
                componentArgs: { "query": this.state.keyword, "filters": this.state.filters }
            })
        }
    }

    onModalClose = () => {
        this.setState((prev) => ({ ...prev, showFilterModal: false }))
    }


    updateFilter = (filters) => {
        let backup_filters = {
            general: { date: { enabled: false, startDate: this.today, endDate: this.today } },
            bundestag: { enabled: true },
            twitter: { enabled: true },
            ep: { enabled: true },
            books: { enabled: true },
            news: { enabled: false, period: 0, maxArticles: 10 }
        }
        // console.log(filters)
        // console.log(backup_filters)

        this.filtersActive = !_.isEqual(filters, backup_filters)
        this.setState(prevState =>
            ({ filters: filters }))


    }

    onFilterClick(event) {
        this.optionTab = 1
        this.setState((prev) => ({ ...prev, showFilterModal: true }))
    }

    onDateClick(event) {
        this.optionTab = 0
        this.setState((prev) => ({ ...prev, showFilterModal: true }))
    }

    render() {
        return (
            <React.Fragment>
                <Modal open={this.state.showFilterModal}>
                    <div>
                        <FilterModal tab={this.optionTab} onClose={this.onModalClose} onFilterSet={this.updateFilter} filters={this.state.filters} />
                    </div>
                </Modal>
                <Paper className={"card"} elevation={1} sx={{ width: "100%", height: "100%" }} style={{ overflow: "auto" }}>
                    {this.renderTopBar()}
                    <div className={"card__body"}>
                        <div className={"card__body-inner"}>

                            {/* Keyword  */}
                            <Stack className={"search"} marginTop={0.5} direction={"row"} spacing={1}>
                                <TextField className={"search__input"} type="search" fullWidth variant="outlined" label={"Search for anything..."} onKeyDown={this.onKeyDown} onChange={(event) => this.setState({ "keyword": event.target.value })} />
                                <div className={"button is--primary"} variant={"contained"} color={"primary"} onClick={this.onClickGo}>Search</div>
                                <div className={"search__filter button is--secondary w-embed " + (this.filtersActive ? "is--active" : "")} onClick={() => (this.onFilterClick())}>
                                    <svg className={"button__icon"} width="16" height="16" viewBox="0 0 20 20" fill="none"
                                        xmlns="https://www.w3.org/2000/svg">
                                        <path d="M5 10H15M2.5 5H17.5M7.5 15H12.5" stroke="#344054"
                                            strokeWidth="1.66667" strokeLinecap="round"
                                            strokeLinejoin="round"></path>
                                    </svg>
                                    Filter
                                </div>
                            </Stack>
                            <Stack direction={"row"}>
                            </Stack>
                        </div>
                    </div>
                </Paper>
            </React.Fragment>);
    }
}
