import GridComponent from "../SuperClasses/GridComponent";
import {
    Alert,
    Box, CircularProgress,
    Paper, Skeleton, SpeedDial,
    Stack, Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import 'typeface-roboto';
import Sparkline from "../InCardWidgets/Sparkline";
import VisualDataGrid from "./VisualDataGrid";
import { GET_STAKEHOLDER, DEFAULT_FETCH_HEADERS } from "../config";

export default class StakeholderCard extends GridComponent {

    constructor(props) {
        super(props);
        this.initComponent({ "type": "Stakeholder", "title": "Stakeholder Profile" })
    }

    componentDidMount() {
        if (!this.promise) {
            const requestOptions = {
                method: 'POST',
                headers: { ...DEFAULT_FETCH_HEADERS().headers, 'Content-Type': 'application/json' },
                body: JSON.stringify({ "oid": this.props["id"], "collection": this.props["collection"] })

            };

            this.promise = fetch(GET_STAKEHOLDER, { ...requestOptions })
                // this.promise = fetch(BASE_URL+"/stakeholders/"+ this.props["id"], DEFAULT_FETCH_HEADERS())
                .then((response) => response.json())
                .then((data) => this.setState({ result: data, stakeholderData: data["stakeholder"] }))
                .catch((error) => this.setState({ error: error }));

        }
    }

    renderActivityDetailCard = () => {
        this.state.parentGrid.createNewComponent(
            { w: 6, h: 6, component: VisualDataGrid, componentArgs: { "channels": this.state.result.channels } })
    }

    renderJKDataPanels = (jkData) => {
        let panels = []
        if (jkData.panels.length === 0) {
            return
        }
        else {
            Object.keys(jkData.panels).forEach(e => {
                panels.push(
                    <div className="meta">
                        <div className="meta__title">{ }</div>
                        <div className="meta__data">{jkData[e]}</div>
                    </div>
                )
            })
        }
        return panels;
    }

    renderAdditionalOffices = (jkData) => {
        let additionalOffices = []
        Object.keys(jkData["additional_duties"]).forEach(e => {
            let office = [];
            let officeTitle = jkData["additional_duties"][e][Object.keys(jkData["additional_duties"][e])[0]];
            Object.keys(jkData["additional_duties"][e]).forEach(x => {
                if (!jkData["additional_duties"][e][x].toLowerCase().includes("kein")) {
                    office.push(
                        <div className="meta">
                            <div className="meta__title">{x.replace("_", " ")}</div>
                            <div className="meta__data">{jkData["additional_duties"][e][x]}</div>
                        </div>
                    )
                }
            })
            additionalOffices.push(
                <div className="card-section">
                    <div className="card-section__header">
                        <div>
                            <h3 className="card-section__title">Add. office - {officeTitle}</h3>
                        </div>
                    </div>
                    <div className="card-section__body">
                        {office}
                    </div>
                </div>
            )
        })
        return additionalOffices;
    }

    renderPrimaryOffice = (jkData) => {
        let attributes = []

        Object.keys(jkData).forEach(e => {

            if (typeof (jkData[e]) === "object") {

            }
            else if (e === "Datum" || e === "Vorname" || e === "Nachname" || jkData[e].toLowerCase().includes("kein")) {

            }
            else if (e === jkData[e] || e.split("_").at(-1) === jkData[e]) {

            }
            else {
                attributes.push(
                    <div className="meta">
                        <div className="meta__title">{e.replace("_", " ")}</div>
                        <div className="meta__data">{jkData[e]}</div>
                    </div>
                )
            }
        })

        return (
            <div className="card-section">
                <div className="card-section__header">
                    <div>
                        <h3 className="card-section__title">JK - Meta</h3>
                    </div>
                </div>
                <div className="card-section__body">
                    {attributes}
                </div>
            </div>)
    }

    renderPanels = (jkData) => {
        let panels = []
        Object.keys(jkData["panels"]).forEach(e => {
            panels.push(
                <div className="meta">
                    <div className="meta__title">{ }</div>
                    <div className="meta__data">{jkData["panels"][e]}</div>
                </div>
            )
        })
        if (panels.length > 0) {
            return (
                <div className="card-section">
                    <div className="card-section__header">
                        <div>
                            <h3 className="card-section__title">Panels</h3>
                        </div>
                    </div>
                    <div className="card-section__body">
                        {panels}
                    </div>
                </div>)
        }
        else {

        }
    }

    renderSubComitees = (jkData) => {
        let subComitees = []
        Object.keys(jkData["sub_comitees"]).forEach(e => {
            subComitees.push(
                <div className="meta">
                    <div className="meta__title">{ }</div>
                    <div className="meta__data">{jkData["sub_comitees"][e]}</div>
                </div>
            )
        })
        if (subComitees.length > 0) {
            return (
                <div className="card-section">
                    <div className="card-section__header">
                        <div>
                            <h3 className="card-section__title">Sub Committees</h3>
                        </div>
                    </div>
                    <div className="card-section__body">
                        {subComitees}
                    </div>
                </div>)
        }
        else {

        }
    }

    renderExtraData = () => {
        if (this.state.stakeholderData.source_specific_data === null || this.state.stakeholderData.source_specific_data === undefined)
            return
        else {
            let extra_data = this.state.stakeholderData.source_specific_data
            let rows = []
            // console.log(extra_data)
            Object.entries(extra_data).forEach((k, v) => {
                if (!k[0].includes('_id')) {
                    rows.push(
                        <div className="meta" key={k[0]}>
                            <div className="meta__title">{k[0]}</div>
                            <div className="meta__data">{k[1]}</div>
                        </div>)
                }
            })
            return (
                <div className="card-section">
                    <div className="card-section__header">
                        <div>
                            <h3 className="card-section__title">Source specific data</h3>
                        </div>
                    </div>
                    <div className="card-section__body">
                        {rows}
                    </div>
                </div>

            )
        }
    }


    renderChannelTab = () => {

        let elements = []
        this.state.result.channels.forEach(e => {
            let dates = Object.keys(e[Object.keys(e)[0]]["resampled_activity"]).map(d => new Date(d / 1000 / 1000).toDateString());
            let docs = Object.values(e[Object.keys(e)[0]]["resampled_activity"]);
            elements.push(
                <div className="channel-activity">
                    <div className="channel-activity__icon w-embed">
                        <img src={"/" + e[Object.keys(e)[0]]["thumbnail"]} loading="lazy" alt="" />
                    </div>
                    <div>
                        <div className="channel-activity__title">{Object.keys(e)[0][0].toUpperCase() + Object.keys(e)[0].slice(1)}</div>
                        <Sparkline values={docs} categories={dates} seriesName={"Docs published:"} />
                    </div>
                </div>
            )
        })
        return elements
    }

    render() {
        if (this.state.stakeholderData === undefined && this.state.error === undefined) {
            return (
                <div className={"card"} sx={{ width: "100%", height: "100%" }} style={{ overflow: "hidden", userSelect: "none" }}>
                    {this.renderTopBar()}
                    <Box display={"flex"} height={"75%"} alignItems={"center"} justifyContent={"center"}><CircularProgress /></Box>
                </div>)
        }
        else if (this.state.error !== undefined) {
            return (
                <Paper className="card">
                    {this.renderTopBar()}
                    <div className="stakeholder">
                        <div className="stakeholder__bg"></div>
                        <div className="stakeholder-profile">
                        </div>
                    </div>
                    <Box display={"flex"} height={"75%"} alignItems={"center"} justifyContent={"center"}>
                        <Alert variant="outlined" severity="error">
                            Sorry, this Stakeholder is not yet part of our Database
                        </Alert>
                    </Box>
                </Paper>)
        }
        return (
            <Paper elevation={0} className="card">
                {this.renderTopBar()}
                <div className="stakeholder">
                    <div className="stakeholder__bg"></div>

                    <div className="stakeholder-profile">
                        <Tooltip title={<><p>{"License: " + this.state.stakeholderData.thumbnail_license}</p><p>
                            {"Creator: " + this.state.stakeholderData.thumbnail_creator}</p> </>} placement={"right"}>
                            <div className="stakeholder-picture">
                                <img src={this.state.stakeholderData.thumbnail} loading="lazy" alt="" className="stakeholder-img" />
                            </div>
                        </Tooltip>
                        <div className="stakeholder-social">
                            <div className="social">
                                <a href="#" className="social__link w-inline-block">
                                    <div className="w-embed">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="https://www.w3.org/2000/svg">
                                            <path
                                                d="M6.2896 18.1256C13.8368 18.1256 17.9648 11.8728 17.9648 6.45035C17.9648 6.27275 17.9648 6.09595 17.9528 5.91995C18.7559 5.33908 19.4491 4.61986 20 3.79595C19.2512 4.12795 18.4567 4.34558 17.6432 4.44155C18.4998 3.92879 19.141 3.1222 19.4472 2.17195C18.6417 2.64996 17.7605 2.98681 16.8416 3.16795C16.2229 2.5101 15.4047 2.07449 14.5135 1.92852C13.6223 1.78256 12.7078 1.93438 11.9116 2.3605C11.1154 2.78661 10.4819 3.46326 10.109 4.28574C9.73605 5.10822 9.64462 6.03067 9.8488 6.91035C8.21741 6.82852 6.62146 6.40455 5.16455 5.66596C3.70763 4.92737 2.4223 3.89067 1.392 2.62315C0.867274 3.52648 0.70656 4.59584 0.942583 5.6135C1.17861 6.63117 1.79362 7.52061 2.6624 8.10075C2.00936 8.08162 1.37054 7.90545 0.8 7.58715V7.63915C0.800259 8.58653 1.12821 9.50465 1.72823 10.2378C2.32824 10.9709 3.16338 11.474 4.092 11.6616C3.4879 11.8263 2.85406 11.8504 2.2392 11.732C2.50151 12.5472 3.01202 13.2602 3.69937 13.7711C4.38671 14.282 5.21652 14.5654 6.0728 14.5816C5.22203 15.2503 4.24776 15.7447 3.20573 16.0366C2.16369 16.3284 1.07435 16.4119 0 16.2824C1.87653 17.4865 4.05994 18.1253 6.2896 18.1224"
                                                fill="#1DA1F2"></path>
                                        </svg>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="stakeholder__title">{this.state.stakeholderData.full_name}</div>
                    <div className="stakeholder__subtitle">{this.state.stakeholderData.abstract}
                    </div>
                </div>
                <div className="card__scroll-area">
                    <div className="card-section">
                        <div className="card-section__header">
                            <div>
                                <h3 className="card-section__title">Meta</h3>
                            </div>
                        </div>
                        <div className="card-section__body">
                            <div className="meta">
                                <div className="meta__title">Stakeholder group</div>
                                <div className="meta__data">{this.state.stakeholderData.origin}</div>
                            </div>
                            <div className="meta">
                                <div className="meta__title">Documents in Database</div>
                                <div className="meta__data">{this.state.result.meta.total_amount}</div>
                            </div>
                        </div>
                    </div>
                    {this.renderExtraData()}
                    <div className="card-section">
                        <div className="card-section__header">
                            <div>
                                <h3 className="card-section__title">Channel Activity</h3>
                            </div>
                            <div>
                                <div className={"link"} onClick={this.renderActivityDetailCard}>View Details</div>
                            </div>
                        </div>
                        <div className="card-section__body">
                            {this.renderChannelTab()}
                        </div>
                    </div>
                </div>
            </Paper>
        );
    }
}