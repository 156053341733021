import React from "react";
import GridComponent from "../SuperClasses/GridComponent";
import {
    Box, Chip,
    CircularProgress,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import { GET_BUNDESTAG, DEFAULT_FETCH_HEADERS } from "../config";
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5'
import StakeholderCard from "./StakeholderCard";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { TimelineOppositeContent, timelineOppositeContentClasses } from "@mui/lab";
import { PDFWidget } from "./PDFWidget";
import {DocumentChatWidget} from "./DocumentChatWidget";



export default class DocumentWidget extends GridComponent {
    constructor(props) {
        super(props);
        this.initComponent({ "type": "Document", "title": "Document" })
        this.mdbExpanded = false;
    }

    componentDidMount() {
        this.setState({ "currentTab": 0 })
        if (!this.promise) {
            const requestOptions = {
                method: 'POST',
                headers: { ...DEFAULT_FETCH_HEADERS().headers, 'Content-Type': 'application/json' },
                body: JSON.stringify({ "_id": this.props["id"] })
            };

            this.promise = fetch(GET_BUNDESTAG, { ...requestOptions })
                .then((response) => response.json())
                .then((data) => this.setState({ documentData: data }))
                .catch((error) => this.setState({ error: error }));

        }
    }

    onOpenPDFClick = (event) => {
        this.state.parentGrid.createNewComponent({ w: 6, h: 6, component: PDFWidget, componentArgs: { "pdf": this.state.documentData.pdf } })
    }

    onChatBtnClick = (event) => {
        this.state.parentGrid.createNewComponent({ w: 12, h: 8, component: DocumentChatWidget, componentArgs: { documents: [{ id: this.props["id"], type: "Bundestag", title: this.state.documentData.titel }] } })
    }


    onPDFLoadSuccess = ({ numPages }) => {
        this.setState({ "totalPages": numPages, "currentPage": 1 })
    }

    renderResort = () => {

        if (!("ressort" in this.state.documentData.doc)) {
            return
        }
        else {
            let resorts = []
            this.state.documentData.doc.ressort.forEach(e => {
                resorts.push(
                    <div className="meta">
                        <div className="meta__title">{resorts.length === 0 ? "Resorts:" : ""}</div>
                        <div className="meta__data">{e["titel"]}</div>
                    </div>
                )
            })
            return resorts
        }
    }

    renderTimeline = () => {

        let timelineItems = [];
        this.state.documentData.proceedings.slice().reverse().forEach((e, i) => {
            timelineItems.push(
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                        {e["datum"]}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        {i === this.state.documentData.proceedings.length - 1 ? <TimelineDot color={"primary"} /> : <TimelineDot />}
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Stack>
                            <Typography display="block" variant={"overline"}>{e["titel"]}</Typography>
                            <Chip color={"primary"} label={e["vorgangstyp"]} />
                        </Stack>
                    </TimelineContent>
                </TimelineItem>
            )
        })
        return (
            <Timeline sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.4,
                },
                padding: 0
            }}>
                {timelineItems}
            </Timeline>)
    }

    renderPDFPages = () => {
        let pages = []
        for (let i = 1; i <= this.state.totalPages; i++) {
            pages.push(<Page pageNumber={i} />)
        }
        return pages;
    }

    onPageChange = (event, value) => {
        this.setState({ "currentPage": value })
    }

    onTabChange = (event, value) => {
        this.setState({ "currentTab": value })
    }

    onMDBClick = (event, id) => {
        this.state.parentGrid.createNewComponent({ w: 3, h: 6, component: StakeholderCard, componentArgs: { "id": id } })
    }

    renderOriginators = () => {
        let originators = []
        this.state.documentData.doc.fundstelle.urheber.forEach(e => {
            originators.push(
                <div key={e} className="meta">
                    <div className="meta__title">{originators.length === 0 ? "Originator(s):" : ""}</div>
                    <div className="meta__data">{e}</div>
                </div>
            )
        })
        return originators
    }

    renderRelatedMDBs = () => {
        let stakeholderList = []
        this.state.documentData.stakeholders.forEach((e, idx) => {
            let fullName = Object.keys(e)[0]
            let cleanedName = fullName.replace("Dr. ", "").replace("Prof. ", "").split(" ")
            let firstName = cleanedName[0][0]
            let lastName = cleanedName[cleanedName.length - 1][0]
            stakeholderList.push(
                <div key={idx} className="related-profile">
                    <div className="related-profile__avatar">{firstName + lastName}</div>
                    <div className="related-profile__name">
                        <div className="related-profile__title">{fullName}</div>
                    </div>
                    <div onClick={(event) => this.onMDBClick(event, Object.values(e)[0])} className="link">View</div>
                </div>)
        })
        return stakeholderList
    }

    render() {
        if (this.state.documentData === undefined || this.state.documentData.doc === undefined) {
            return (
                <Paper className={"card"} elevation={0} >
                    <Stack height={"100%"} overflow={"hidden"}>
                        {this.renderTopBar()}
                        <Box display={"flex"} height={"75%"} alignItems={"center"} justifyContent={"center"}><CircularProgress /></Box>
                    </Stack>
                </Paper>)
        }
        else {
            return (
                <Paper elevation={0} className={"card"} >
                    {this.renderTopBar()}
                    <div className="document-title">
                        <h1 className="document-title__title">{this.state.documentData.doc.typ === "Dokument" ? this.state.documentData.doc.titel : this.state.documentData.doc.vorgangsbezug.at(-1).titel}</h1>
                        <div className="document-title__subtitle">{this.state.documentData.doc.drucksachetyp}</div>
                        <div className="button is--primary dropdown-form" onClick={this.onOpenPDFClick}>Open Document</div>
                        <div className="button is--primary" onClick={this.onChatBtnClick}>Chat with AI</div>
                    </div>
                    <div className="card__scroll-area">
                        <div className="card-section">
                            <div className="card-section__header">
                                <div>
                                    <h3 className="card-section__title">Document Information</h3>
                                </div>
                            </div>
                            <div className="card-section__body">
                                <div className="meta">
                                    <div className="meta__title">Bundestag ID</div>
                                    <div className="meta__data">{this.state.documentData.doc.id}</div>
                                </div>
                                <div className="meta">
                                    <div className="meta__title">Date</div>
                                    <div className="meta__data">{this.state.documentData.doc.datum}</div>
                                </div>
                                {this.renderOriginators()}
                                {this.renderResort()}
                                <div className="meta">
                                    <div className="meta__title">Drucksache</div>
                                    <div className="meta__data">{this.state.documentData.doc.dokumentnummer}</div>
                                </div>
                                <div className="meta">
                                    <div className="meta__title">Electoral Period</div>
                                    <div className="meta__data">{this.state.documentData.doc.wahlperiode}</div>
                                </div>
                                <div className="meta">
                                    <div className="meta__title">External Link</div>
                                    <a href={this.state.documentData.doc.fundstelle.pdf_url} target="_blank" download>Go to Source PDF</a>
                                </div>
                            </div>
                        </div>
                        <div className="card-section">
                            <div className="card-section__header">
                                <div>
                                    <h3 className="card-section__title">Related MDBs</h3>
                                    <div className="badge">{this.state.documentData.stakeholders.length}</div>
                                </div>
                            </div>
                            <div className="card-section__body">
                                {this.renderRelatedMDBs()}
                            </div>
                        </div>
                        <div className={"card-section"}>
                            <div className="card-section__header">
                                <div>
                                    <h3 className="card-section__title">Timeline</h3>
                                </div>
                            </div>
                            <div className="card-section__body">
                                {this.renderTimeline()}
                            </div>
                        </div>
                    </div>
                </Paper>
            )
        }
    }
}
