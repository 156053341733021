import GridComponent from "../SuperClasses/GridComponent";
import {
    Alert,
    Box, Button,
    CircularProgress,
    Divider,
    IconButton,
    Paper, Snackbar,
    Stack, Tab, Tabs,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import SearchResultCharts from "../InCardWidgets/SearchResultCharts";
import _ from "lodash";
import PDataGrid from "../InCardWidgets/PDataGrid";
import { SEARCH_NEWS, SEARCH_DOCUMENTS, DEFAULT_FETCH_HEADERS } from "../config";
import { DataGrid } from "@mui/x-data-grid";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import NewsWidget from "./NewsWidget";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";


export const getColumnWidth = (data, accessor, headerText) => {
    const cellLength = Math.max(
        ...data.map(row => {
            let value = '';

            if (typeof accessor === 'string') {
                value = _.get(row, accessor);
            } else {
                value = accessor(row);
            }

            if (typeof value === 'number') return value.toString().length;
            return (value || '').length;
        }),
        headerText.length
    );

    const magicSpacing = 12;
    return cellLength * magicSpacing;
};


export default class SearchResultGrid extends GridComponent {
    constructor(props) {
        super(props);
        this.state = { ...props, currentTab: 0, showFilters: false }
        this.initComponent({ "type": "ResultGrid", "title": "Search Results:'" + props.query + "'" })
    }

    componentDidMount() {
        if (!this.promise) {
            if (this.state.filters.news.enabled) {
                const newsRequestOptions = {
                    method: 'POST',
                    headers: { ...DEFAULT_FETCH_HEADERS().headers, 'Content-Type': 'application/json' },
                    body: JSON.stringify(
                        {
                            search_term: this.state.query,
                            search_periode: this.state.filters.news.period,
                            max_articles: this.state.filters.news.maxArticles
                        })
                };
                fetch(SEARCH_NEWS, { ...newsRequestOptions })
                    .then((response) => response.json())
                    .then((data) => this.setState({ newsResults: data, showNewsFeedback: true }))
                    .catch((error) => this.setState({ newsError: error }));
            }
            const requestOptions = {
                method: 'POST',
                headers: { ...DEFAULT_FETCH_HEADERS().headers, 'Content-Type': 'application/json' },
                body: JSON.stringify({ keyword: this.state.query, filters: this.state.filters })
            };
            this.promise = fetch(SEARCH_DOCUMENTS, { ...requestOptions })
                .then((response) => response.json())
                .then((data) => this.setState({ results: data["objects"], chartData: data["graph_data"] }))
                .catch((error) => this.setState({ error: error }));

        }
    }

    onNewsCellClick = (event, context) => {
        if (event.field === "Title") {
            this.state.parentGrid.createNewComponent({
                w: 3,
                h: 6,
                component: NewsWidget,
                componentArgs: { "id": event.row["id"] }
            })
        }
    }

    renderNewsTab = () => {
        if (this.state.newsResults === undefined) {
            return (
                <Box display={"flex"} height={"75%"} alignItems={"center"} justifyContent={"center"}><CircularProgress /></Box>)
        } else if (this.state.newsResults.length === 0) {
            return (<Box display={"flex"} height={"75%"} alignItems={"center"} justifyContent={"center"}>
                <Alert variant="outlined" severity="warning">
                    Sorry, we could not get any results for your query
                </Alert>
            </Box>)
        } else {
            let columns = []
            Object.keys(this.state.newsResults[0]).forEach(e => {
                columns.push({
                    field: e,
                    headerClassName: "datagrid_headers",
                    headerName: e,
                    flex: 1,
                    hide: e.toLowerCase().includes("id"),
                    maxWidth: getColumnWidth(this.state.newsResults, e, e),
                })
            })
            return (
                <div className={"card__table"}>
                    <DataGrid rows={this.state.newsResults}
                        columns={columns}
                        parent={this}
                        onCellClick={this.onNewsCellClick}
                        getCellClassName={(params) => {
                            if ((params.field !== "Title")) {
                                return '';
                            }
                            return "clickable_datagrid_cells";
                        }} />
                </div>)
        }
    }


    onShowChartsClicked = (event) => {
        console.log('jejej')
        if (this.state.results) {
            this.state.parentGrid.createNewComponent({
                w: 5,
                h: 5,
                component: SearchResultCharts,
                componentArgs: { "data": this.state.chartData }
            })
        }
    }

    setupColumns = () => {
        let cols = []
        if (this.state.results.length > 0) {
            Object.keys(this.state.results[0]).forEach(e => {
                cols.push({
                    field: e,
                    headerClassName: "datagrid_headers",
                    headerName: e,
                    accessor: 'accessor',
                    editable: false,
                    hide: e.toLowerCase().includes("id"),
                    maxWidth: e != "Type" ? getColumnWidth(this.state.results, e, e) : 150,
                    flex: 1,
                })
            })
        }
        else {
            cols = []
        }
        return cols
    }


    render() {
        let mainContent;

        if (this.state.results === undefined && this.state.error === undefined) {
            mainContent =
                <Box display={"flex"} height={"75%"} alignItems={"center"} justifyContent={"center"}><CircularProgress /></Box>
        } else if (this.state.results !== undefined) {
            const cols = this.setupColumns()
            if (!this.state.filters.news.enabled)
                mainContent =
                    <PDataGrid rows={this.state.results} columns={cols} showFilters={this.state.showFilters}
                        parent={this} />
            else {
                mainContent =
                    <React.Fragment>
                        <div className="card__tabs">
                            <div className="tabs">
                                <div className={"tabs__tab " + (this.state.currentTab === 0 ? "is--active" : "")}
                                    onClick={() => (this.setState((prev) => ({ ...prev, currentTab: 0 })))}>Documents
                                </div>
                                <div className={"tabs__tab " + (this.state.currentTab === 1 ? "is--active" : "")}
                                    onClick={() => (this.setState((prev) => ({ ...prev, currentTab: 1 })))}>News
                                </div>
                            </div>
                        </div>
                        {this.state.currentTab === 0 ?

                            <PDataGrid rows={this.state.results} showFilters={this.state.showFilters}
                                columns={cols} parent={this} /> :
                            this.renderNewsTab()
                        }
                    </React.Fragment>
            }
        } else {

            mainContent = <Box display={"flex"} height={"75%"} alignItems={"center"} justifyContent={"center"}>
                <Alert variant="outlined" severity="error">
                    Sorry, we could not fetch your data (Server Error)
                </Alert>
            </Box>
        }
        return (
            <React.Fragment>
                <Paper className={"card"} elevation={0} style={{ overflow: "auto" }}>
                    <Stack sx={{ padding: "5px 15px 5px 15px" }} direction="row"
                        className={"drag-handle card__header"}>
                        <div className={"card__title"}>{this.properties.title}</div>
                        <div className={"card_controls"}>
                            <IconButton className={"control-button"} size={"small"}>
                                <HelpOutlineIcon className={"control-button__inner"} />
                            </IconButton>
                            <IconButton className={"control-button"} size={"small"} onClick={this.onShowChartsClicked}>
                                <InsertChartOutlinedIcon className={"control-button__inner"} />
                            </IconButton>
                            <IconButton
                                className={"control-button " + (this.state.showFilters === true ? "is--active" : "")}
                                size={"small"}
                                onClick={() => (this.setState((prev) => ({
                                    ...prev,
                                    showFilters: !this.state.showFilters
                                })))}>
                                <FilterAltOutlinedIcon className={"control-button__inner"} />
                            </IconButton>

                            <IconButton className={"control-button"} size={"small"} onClick={this.onCloseClicked}>
                                <CloseIcon className={"control-button__inner"} />
                            </IconButton>
                        </div>
                    </Stack>
                    {mainContent}
                </Paper>,
                <Snackbar open={this.state.newsResults !== undefined && this.state.showNewsFeedback}
                    autoHideDuration={5000}
                    onClose={(event) => this.setState(prev => ({ ...prev, showNewsFeedback: false }))}>
                    {
                        this.state.newsError !== undefined ?
                            (<Alert severity="error">Could not fetch your news :(</Alert>) :
                            (<Alert severity="success">Successfully fetched your news!</Alert>)
                    }
                </Snackbar>
            </React.Fragment>
        )
    }

}