// @ts-ignore
import React, { useEffect, useState } from "react"
import { GET_STAKEHOLDERS, DEFAULT_FETCH_HEADERS } from "../config"
import {
  Alert,
  Autocomplete,
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Stack,
  TextField,
} from "@mui/material"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import CloseIcon from "@mui/icons-material/Close"
import StakeholderCard from "../StandaloneWidgets/StakeholderCard"

type StakeholderView = {
  fullName: string
  id: string
}

type StakeholderModalProps = {
  open: boolean
  gridRef: React.RefObject<any>
  closeCallback: Function
}

const StakeholderModal = (props: StakeholderModalProps) => {
  const [open, setOpen] = useState(props.open)
  const [stakeholders, setStakeholders] = useState<StakeholderView[]>([])
  const [group, setGroup] = useState("Bundestag")
  const [error, setError] = useState(false)
  const [selection, setSelection] = useState<string | null>(null)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setOpen(props.open)
    const fetchData = async () => {
      setLoaded(false)
      const requestOptions = {
        method: "POST",
        headers: {
          ...DEFAULT_FETCH_HEADERS().headers,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ collection: group }),
      }
      const response = await fetch(GET_STAKEHOLDERS, { ...requestOptions })
      // const response = await fetch(BASE_URL + "/stakeholders/?group=" + group, DEFAULT_FETCH_HEADERS())
      const stakeholders = (await response.json()) as StakeholderView[]
      setStakeholders(stakeholders)
      setLoaded(true)
      setError(false)
    }
    if (props.open) {
      fetchData().catch((r) => {
        setError(true)
      })
    }
  }, [props, group])

  function onClose() {
    setOpen(false)
    props.closeCallback()
  }

  function onConfirm() {
    if (props.gridRef.current) {
      props.gridRef.current.createNewComponent({
        w: 3,
        h: 6,
        component: StakeholderCard,
        componentArgs: { id: selection, collection: group },
      })
    }
    onClose()
  }

  const renderContent = () => {
    return (
      <Stack spacing={1}>
        <Autocomplete
          id={"group"}
          fullWidth
          autoHighlight
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Select stakeholder group"
            />
          )}
          defaultValue={group}
          onChange={(event, value) => setGroup(value ?? "")}
          options={["Bundestag", "EP"]}
        />
        <Autocomplete
          id={"type"}
          fullWidth
          autoHighlight
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Select stakeholder profile"
            />
          )}
          onChange={(event, value) =>
            setSelection(stakeholders.filter((x) => x.fullName === value)[0].id)
          }
          options={stakeholders.map((x) => x.fullName)}
        />
      </Stack>
    )
  }

  const renderLoad = () => {
    return (
      <Box
        display={"flex"}
        height={"75%"}
        alignItems={"center"}
        justifyContent={"center"}>
        <CircularProgress />
      </Box>
    )
  }

  const renderError = () => {
    return (
      <Alert variant="outlined" severity="error">
        Could not fetch stakeholders
      </Alert>
    )
  }

  return (
    <Modal open={open}>
      <div>
        <div className="modal-wrapper">
          <div className="modal">
            <div className="modal__header">
              <div className="modal__title">
                <div className="modal__title-icon w-embed">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="https://www.w3.org/2000/svg">
                    <path
                      d="M6 12.5H18M3 6.5H21M9 18.5H15"
                      stroke="currentColor"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"></path>
                  </svg>
                </div>
                <div>Stakeholder selection</div>
              </div>
              <div className="card_controls">
                <IconButton className={"control-button"} size={"small"}>
                  <HelpOutlineIcon />
                </IconButton>
                <IconButton
                  className={"control-button"}
                  size={"small"}
                  onClick={onClose}>
                  <CloseIcon className={"control-button__inner w-embed"} />
                </IconButton>
              </div>
            </div>
            <div className="modal__section">
              {loaded
                ? error
                  ? renderError()
                  : renderContent()
                : renderLoad()}
            </div>
            <div className="modal__footer">
              <div className="modal__actions">
                <div className="modal__actions-item">
                  <div onClick={onClose} className="button is--block">
                    Cancel
                  </div>
                </div>
                <div className="modal__actions-item">
                  <div
                    className="button is--primary is--block"
                    onClick={onConfirm}>
                    Add
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default StakeholderModal
