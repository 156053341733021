import GridComponent from "../SuperClasses/GridComponent";
import { CircularProgress, Paper, TextField, InputLabel, MenuItem, FormControl, Select, Stack, Divider } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import { EXTRACT_ARTICLE, YT_TRANSCRIPT, PDF_SUMMARY, DEFAULT_FETCH_HEADERS } from "../config";

const LIST = [
  { label: "Youtube transcript", value: "YT_TRANSCRIPT" },
  { label: "Article summary", value: "EXTRACT_ARTICLE" },
  { label: "PDF summary", value: "PDF_SUMMARY"},
]

export default class MediaSummarizerWidget extends GridComponent {
  constructor(props) {
    super(props);
    this.state = { ...props, topic: null, loading_intro: false, loading_summary: false, loading_links: false, intro: null, title: null, topics: [], links: [], date: null, displayError: false };
    this.initComponent({ type: "Summarizer", title: "Summarizer" });
  }

  fetchData = () => {
    if (!this.state.url || !this.state.topic) {
      toast.error("URL or topic not provided");
      return;
    }


    const newsRequestOptions = {
      method: 'POST',
      headers: { ...DEFAULT_FETCH_HEADERS().headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(
        {
          url: this.state.url,
        })
    };

    let endpoint;
    if (this.state.topic === 'YT_TRANSCRIPT') {
      endpoint = YT_TRANSCRIPT;
    } else if (this.state.topic === 'EXTRACT_ARTICLE') {
      endpoint = EXTRACT_ARTICLE;
    } else if (this.state.topic === 'PDF_SUMMARY') {
      endpoint = PDF_SUMMARY; // You need to define this constant
    }

    if (endpoint) {
      this.setState({ loading_intro: true });
      fetch(endpoint, newsRequestOptions)
        .then(response => response.json())
        .then(data => {
          if (this.state.topic === 'YT_TRANSCRIPT') {
            this.setState({ title: data.title, intro: data.transcript.replace(/\n/g, '<br />'), loading_intro: false });
          } else if (this.state.topic === 'EXTRACT_ARTICLE') {
            this.setState({ title: data.title, intro: data.summary.replace(/\n/g, '<br />'), loading_intro: false });
          } else if (this.state.topic === 'PDF_SUMMARY') {
            // Handle the response for the PDF summary
            this.setState({ title: data.title, intro: data.summary.replace(/\n/g, '<br />'), loading_intro: false });
          }
        })
        .catch(error => {
          this.setState({ displayError: true, loading_intro: false });
          toast.error("Error fetching data");
        });
    }
  };

  handleButtonClick = () => {
    this.fetchData();
  };


  handleChange = (event) => {
    const loadingState = event.target.value && this.state.date
    this.setState({ ...this.state, topic: event.target.value, loading_intro: loadingState, loading_summary: loadingState, loading_links: loadingState, displayError: false });
  };


  render_main_content() {
    if (!this.state.topic) {
      return <p className="helper-text">Please select a topic.</p>;
    }

    if (this.state.topic && this.state.displayError) {
      return <p className="helper-text error-text">Resource not found! Please select another topic or date.</p>;
    }

    if (this.state.loading_intro || this.state.loading_summary || this.state.loading_links) {
      return (
        <div className="flex-center">
          <CircularProgress />
        </div>
      );
    } else {

      return (
        <div>
          <h2>{this.state.title}</h2>
          <Divider style={{ margin: '20px 0' }} />
          <div className="block-gap" dangerouslySetInnerHTML={{ __html: this.state.intro }}></div>

          {this.state.topics?.map((el, index) => {
            return (
              <div key={index} className="block-gap">
                <a className="underline-link large-link" href={el.url} target="_blank">{el.title}</a>
                <div dangerouslySetInnerHTML={{ __html: el.summary }}></div>
              </div>
            )
          })}

          {/* {this.state.links && (
            <>
              <h2>Presseschau</h2>
              {this.state.links?.map((el, index) => <a key={index} href={el.url} target="_blank" className="underline-link">{el.text}</a>)}
            </>
          )} */}
        </div>
      );
    }
  }

  render() {
    return (
      <Paper className="card">
        {this.renderTopBar()}
        <div className="card__body">
          <div className="card__body-inner">
            <Stack className={"search"} marginTop={0.5} direction={"row"} spacing={1}>
              <FormControl fullWidth className="url-input-field" size="small">
                <TextField
                  fullWidth
                  type="search"
                  variant="outlined"
                  label="Url..."
                  onKeyDown={this.onKeyDown}
                  onChange={(event) => this.setState({ url: event.target.value })}
                />
              </FormControl>

              <FormControl fullWidth size="small" className="dropdown-form">
                <InputLabel>Select a type</InputLabel>
                <Select
                  fullWidth
                  value={this.state.topic}
                  label="Select a type"
                  onChange={(e) => this.handleChange(e)}
                >
                  {_.map(LIST, el => <MenuItem value={el.value}>{el.label}</MenuItem>)}
                </Select>
              </FormControl>
              <div className={"button is--primary"} variant={"contained"} color={"primary"} onClick={this.handleButtonClick}>Search</div>
            </Stack>
          </div>
          <div className="card__body-inner">{this.render_main_content()}</div>
        </div>
      </Paper>


    );
  }
}
