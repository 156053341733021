import GridComponent from "../SuperClasses/GridComponent";
import { CircularProgress, Paper, TextField, InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React from "react";
import {toast} from "react-toastify";
import _ from "lodash";
import { GET_NEWSLETTER, DEFAULT_FETCH_HEADERS } from "../config";

const LIST = [
  { label: "Energy", value: "pool_energy" },
  { label: "Sustainability", value: "pool_sustainability" },
  { label: "Health", value: "pool_health" },
  { label: "Infrastructure and Mobility", value: "pool_infra_mobi" },
  { label: "Europe", value: "pool_europe" }
]

export default class NewsletterWidget extends GridComponent {
  constructor(props) {
    super(props);
    this.state = { ...props, topic: null, loading_intro: false, loading_summary: false, loading_links: false, intro: null, topics: [], links: [], date: null, displayError: false };
    this.initComponent({ type: "Newsletter", title: "Newsfeed" });
  }

  componentDidUpdate() {
    if ((this.state.loading_intro || this.state.loading_summary || this.state.loading_links) && !this.state.displayError) {
      const requestOptions = {
        method: "GET",
        headers: {
          ...DEFAULT_FETCH_HEADERS().headers,
          "Content-Type": "application/json",
        }
      };

      this.promise = fetch(`${GET_NEWSLETTER}/newsletter?date=${this.state.date.format("YYYYMMDD")}&topic=${this.state.topic}_intro`, { ...requestOptions })
        .then((response) => response.json())
        .then((data) => this.setState({ ...this.state, intro: data.items[0], loading_intro: false }))
        .catch((error) => this.setState({ ...this.state, displayError: true }));

      this.promise = fetch(`${GET_NEWSLETTER}/newsletter?date=${this.state.date.format("YYYYMMDD")}&topic=${this.state.topic}`, { ...requestOptions })
        .then((response) => response.json())
        .then((data) => this.setState({ ...this.state, topics: data.items, loading_summary: false }))
        .catch((error) => this.setState({ ...this.state, displayError: true }));

      this.promise = fetch(`${GET_NEWSLETTER}/newsletter?date=${this.state.date.format("YYYYMMDD")}&topic=${this.state.topic}_presseschau`, { ...requestOptions })
        .then((response) => response.json())
        .then((data) => this.setState({ ...this.state, links: data.items, loading_links: false }))
        .catch((error) => this.setState({ ...this.state, displayError: true }));
    }
  }

  handleChange = (event) => {
    const loadingState = event.target.value && this.state.date
    this.setState({ ...this.state, topic: event.target.value, loading_intro: loadingState, loading_summary: loadingState, loading_links: loadingState, displayError: false });
  };

  handleDateChange = (event) => {
    const loadingState = event && this.state.topic
    this.setState({ ...this.state, date: event, loading_intro: loadingState, loading_summary: loadingState, loading_links: loadingState, displayError: false });
  }

  render_main_content() {
    if (!this.state.topic || !this.state.date) {
      return <p className="helper-text">Please select a topic and date.</p>;
    }

    if (this.state.topic && this.state.date && this.state.displayError) {
      return <p className="helper-text error-text">Resource not found! Please select another topic or date.</p>;
    }

    if (this.state.loading_intro || this.state.loading_summary || this.state.loading_links) {
      return (
        <div class="flex-center">
          <CircularProgress />
        </div>
      );
    } else {
      return (
        <div>
          <h2>InQ Daily - {LIST.find(el => el.value == this.state.topic).label}</h2>
          <div className="block-gap" dangerouslySetInnerHTML={{__html: this.state.intro}}></div>

          {this.state.topics?.map((el, index) => {
            return (
              <div key={index} className="block-gap">
                <a className="underline-link large-link" href={el.url} target="_blank">{el.title}</a>
                <div dangerouslySetInnerHTML={{__html: el.summary}}></div>
              </div>
            )
          })}

          {this.state.links && (
            <>
              <h2>Presseschau</h2>
              {this.state.links?.map((el, index) => <a key={index} href={el.url} target="_blank" className="underline-link">{el.text}</a>)}
            </>
          )}
        </div>
      );
    }
  }

  render() {
    return (
      <Paper className="card">
        {this.renderTopBar()}
        <div className="card__body">
          <div className="card__body-inner">
            <div className="form-container">
              <FormControl fullWidth size="small" className="dropdown-form">
                <InputLabel>Select a topic</InputLabel>
                <Select
                  value={this.state.topic}
                  label="Select a topic"
                  onChange={(e) => this.handleChange(e)}
                >
                  {_.map(LIST, el => <MenuItem value={el.value}>{el.label}</MenuItem>)}
                </Select>
              </FormControl>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  size={"small"}
                  inputFormat="DD/MM/YYYY"
                  disableFuture
                  value={this.state.date}
                  label="Select a date"
                  openTo="day"
                  onChange={(date) => this.handleDateChange(date)}
                  renderInput={(params) => <TextField  size={"small"}{...params} />}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="card__body-inner">{this.render_main_content()}</div>
        </div>
      </Paper>
    );
  }
}
